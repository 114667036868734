import axios from "axios";
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario';
import {  useState } from 'react';
import { Spinner } from 'reactstrap';
import DevuelveInscriptos from './devuelveInscriptos';

function Ingresos (props) {
const cookies=new Cookies();

const ID=cookies.get('id');

const [cargando, setCargando] = useState(true);

const[usuariosVarios, setUsuariosVarios]=useState(null)
const[estaOK, setEstaOK]=useState(false)
const api=props.datos.api
  if(!ID){
     window.location.href='./';
 }
 
 const URI='https://wwwa.isepsantafe.net.ar/expedientes/procesos/devuelvePostulantes/'+api


      
  const getUsuarios=async ()=>{
   try {
   
     const postulantes=await axios.post(URI+"?condicion="+props.etapa.nombreEtapa)
    if(postulantes){

      setUsuariosVarios(postulantes)
      setEstaOK(true);setCargando(false)

    }
    
       } catch (error) {
        alert("algo fallo!")
      }
     }

  if(!estaOK){
    getUsuarios()
   
  }
 

  return (
    <div  id='button'>

     <div>
    
      </div>
    <div style={{textAlign:'center'}}>{cargando && (<h2>Cargando Postulantes {props.datos.proceso}...</h2>)}
    {cargando && (<Spinner color='priority'/>)}
    </div>  
     
     <div style={{textAlign:'center'}}>
      {usuariosVarios?<section  className="datos-personales">
      
        <DevuelveInscriptos datos={usuariosVarios} proceso={props.datos} etapa={props.etapa}/>
         
          <hr />
         
           </section>:""}
           </div>
        
      
    </div>
  );
};
export default Ingresos;


 

  
 
