import Cookies from 'universal-cookie';
import { Table } from "reactstrap";
import { CSVLink } from 'react-csv';
import QRGenerator from '../tools/QRgenerator';
function ModuloDevuelveCadetes (props) {
  const cookies=new Cookies();
  const headers = [
    { label: "Nro Control", key: "id" },
    { label: "DNI", key: "cadeteDni" },
    { label: "Nombres", key: "cadeteNombre" },
    { label: "Apellido", key: "cadeteApellido" },
    { label: "Genero", key: "cadeteGenero" },
    { label: "Sede", key: "cadeteSede" },
    { label: "Estado", key: "cadeteEstado" },
    {label:"Domicilio",key:"cadeteDomicilio"},
    {label:"Ciudad",key:"cadeteCiudad"},
    {label:"U.R.",key:"cadeteUR"},
    {label:"Telefono",key:"cadeteCelular"},
  
  ];
  var cantidad2=1;
    
    const usuarioPrivilegios = (

      <>
<Table className="table-primary" bordered  size="sm">
<tr >
      <th>
        #
      </th>
      <th>
        DNI
      </th>
      <th>
      Apellido
      </th>
      <th>
      Nombres
      </th>
      <th>
      Sede
      </th>
      <th>
      Estado
      </th>

      <th>
       Editar
      </th>
    </tr>
     
      {!props.cosas?"":props.cosas.map((cosas,index) =><>
 
        <tr style={{ overflowY: "auto" }} key={cosas.id}>

      <th scope="row">
     {cantidad2++}
     
      </th>
      
      <th>
      {cosas.cadeteDni}  
      </th>    <th>
      { cosas.cadeteApellido}
      </th>
      <th>
      { cosas.cadeteNombre}
      </th>
    
      <th>
      { cosas.cadeteSede}
      </th>
      <th>
      { cosas.cadeteEstado}
      </th>
      {<button className='btn btn-success' onClick={()=>props.myID(cosas)}>   
       X </button>}
      </tr>
</>)}
      </Table></>

  );  
  return (
    <div>
      <h4>Cantidad de Cadetes en esta condicion: <h3>{cantidad2}</h3></h4>
        <CSVLink data={props.cosas} filename={"Listado"}className="btn btn-primary"separator={";"}
      target="_blank" headers={headers}> Bajar listado</CSVLink>
      {usuarioPrivilegios}
    
    </div>
  );
};
export default ModuloDevuelveCadetes;
