import { useForm } from 'react-hook-form';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import { useState } from 'react';
import swal from 'sweetalert'
import Modal from 'react-modal';
import convierteBaja from './convierteBaja.js'
import convierteSoloEstado from './convierteSoloEstado.js'


function ModalBaja (props) {
  const cookies=new Cookies();
  const navitate = useNavigate();
  const ID=cookies.get('id');
  const [modalCadeteIsOpen, setCadeteIsOpen] = useState(false);
 
  const customStyles = {
    content: {/*
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
 */
    },
  };

 
  var cierro=false;
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.net.ar/expedientes/'
  
const parametro=props.datosCadete
const usuario=ObtieneDatosCookies()
const datosCurso=props.Datos

  const store=async (data)=>{
    const client = axios.create({
      baseURL: process.env.REACT_APP_BASEURL+"cadetes/"
      });
   const respuesta=await client.post('/creaBaja/',convierteBaja(data)).then(modificaEstado(parametro,"BAJA"))
   queHago(respuesta.data).then(cierro?()=>props.myID("dale"):"")
}

  const queHago=async(respuesta)=>{
    console.log(respuesta)
    if(respuesta.message==="Registro creado correctamente!!"){
      swal("Exito en la Baja de "+parametro.cadeteDni)
      cierro=true
    }
    else{
      swal("Algo Fallo!, consulte con el area Tecnica error A004!!!")
     
    
       }}

const modificaEstado=async(cadeteDni,estado)=>{

  console.log(cadeteDni)
          const client = axios.create({
           
              baseURL:process.env.REACT_APP_BASEURL+"cadetes/"
              });
              try {
        
              
        
                await client.put('actualiza/'+cadeteDni.cadeteDni,convierteSoloEstado(parametro,estado))
                }
              catch (error) {}
         
        }
  const {register, formState: { errors }, handleSubmit,} = useForm({

  });
  

const categorias=devuelveCategorias()

  const baja = (informacionBaja) => {

store(informacionBaja)

 
  }

//Aca enpieza el react lo anterior es javascript y hooks


  return (
    <>
    <div  >
   
      <form  onSubmit={handleSubmit(baja)}>


<div class="row">
<div class='visually-hidden' >

          <div class="form-floating mb-3">
                  <input value ={parametro.cadeteDni} type="text" class="form-control" id="floatingInput" placeholder="idCadete" 
         {...register('cadeteDni', {
       
          })}/>
        <label for="floatingInput">DNI Cadete</label>
    </div>
      <div class="form-floating mb-3" >
      <input value ={usuario.id}type="text" class="form-control" id="floatingNombres" placeholder="idUsuario" 
      {...register('idUsuario', {
      
      })}/>
      <label for="floatingNombres">Identificacion Usuario</label>
    </div>
    <div class="form-floating mb-3">
      <input value={usuario.sedeID}type="number" class="form-control" id="floatingUsuario" placeholder="Sede Usuario" 
      {...register('idSede', {
      
      })}/>
      <label for="floatingUsuario">Sede ID</label>
    </div>
  
</div>

<div class="row">

          <div class='input-group-text' >
          <div class="form-floating mb-3">
<select class="form-select" id="floatingCadeteGenero" aria-label="CadeteGenero"
  {...register('motivo', {
    required: true
  })}
  >
   
    <option class="mb-3" value="LTB">LTB</option>
    <option class="mb-3" value="Titulo Secundario">Titulo Secundario</option>
    <option class="mb-3" value="Falta de Regularidad">Falta de Regularidad</option>   
     <option class="mb-3" value="Antecedentes">Antecedentes</option>
     <option class="mb-3" value="Perdida de Aptitud Fisica/Psicologica">Perdida de Aptitud Fisica/Psicologica</option>
    
    
  </select>
  <label for="form-floating mb-3">Motivo de la BAJA</label>
</div>
<div class="form-floating mb-3">
  <input value={parametro.promocion} class="form-control" placeholder="CURSO" id="floatingCurso" 
   {...register('promocion', {
    

  })}/>
  <label for="floatingCurso">Promoción</label>
</div>
     
<div>
  
</div>
</div>

<div class='card-header mb-3' >
    {props.estadoCadete.ltbs&&props.estadoCadete.ltbs[0]?<>
      <div class="form-floating">
  <textarea  class="form-control" style={{height:"250px",resize:"none"}} placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('descripcion', {
    required: true,
    maxLength:1000 
  })}></textarea>
  <label for="floatingDescripcion">{"Descripcion del LTB:"+ props.estadoCadete.ltbs[0]? props.estadoCadete.ltbs[0].descripcion:"Descripción"}</label>
</div>    
    </>:<div class="form-floating">
  <textarea  class="form-control" style={{height:"250px",resize:"none"}} placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('descripcion', {
    required: true,
    maxLength:1000 
  })}></textarea>
  <label for="floatingDescripcion">Descripción</label>
</div>    }


</div>
<div class='input-group-text' >

          <div class="form-floating mb-3">
                  <input  type="text" class="form-control" id="floatingresolucionBaja" placeholder="resolucionBaja" 
         {...register('resolucionBaja', {
          required: true
          })}/>
        <label for="floatingresolucionBaja">Resolucion de Baja</label>
    </div>
      <div class="form-floating mb-3" >
      <input type="date" class="form-control" id="floatingfechaBaja" placeholder="fechaBaja" 
      {...register('fechaBaja', {
        required: true
      })}/>
      <label for="floatingfechaBaja">Fecha de Res. de Baja</label>
    </div>
    
  
</div>

</div>
<div class='card-header mb-3' >
    
<div class="form-floating">
<input type="submit" value="Dar de Baja"  className="btn btn-success" />
<input type="button" value="Cancelar"  className="btn btn-danger" onClick={()=>props.myID("dale")}/>
</div></div></div>
 </form>

   
        
        </div>
 
        </>
  )}

export default ModalBaja;
