import React from "react";

const ListeaAusentes = ({ datos, fecha }) => {
  // Ordenamos los datos por nombre de la sección
  const datosOrdenados = [...datos].sort((a, b) => {
    if (a.Cadete.Seccion.nombre < b.Cadete.Seccion.nombre) return -1;
    if (a.Cadete.Seccion.nombre > b.Cadete.Seccion.nombre) return 1;
    return 0;
  });
console.log(process.env.REACT_APP_KEY)
  // Agrupamos los cadetes por sección
  const agrupadosPorSeccion = datosOrdenados.reduce((acc, user) => {
    const seccionNombre = user.Cadete.Seccion.nombre;

    if (!acc[seccionNombre]) {
      acc[seccionNombre] = [];
    }

    acc[seccionNombre].push(user);
    return acc;
  }, {});

  return (
    <div
      style={{
        height: "400px",
        overflowY: "scroll",
        textAlign: "left",
        fontSize: "13px",
        lineHeight: "1px",
      }}
    >
      <h1>
        Ausentes de fecha: {fecha} ({datos.length})
      </h1>

      {datos.length > 0 ? (
        Object.entries(agrupadosPorSeccion).map(([seccionNombre, cadetes]) => (
          <div key={seccionNombre}>
            <h3>
              Sección: {seccionNombre} - Ausentes: {cadetes.length}
            </h3>
            <table>
              <tbody>
                {cadetes.map((user) => {
                  const { cadeteDni, cadeteApellido, cadeteNombre, Seccion } =
                    user.Cadete;

                  return (
                    <tr key={cadeteDni}>
                      <td>
                        <p>
                          <strong>DNI:</strong> {cadeteDni}
                        </p>
                      </td>
                      <td>
                        <p>
                          {cadeteApellido}, {cadeteNombre}
                        </p>
                      </td>
                      <td>
                        <p>
                          <strong>TIPO:</strong> {user.tipo}
                        </p>
                      </td>

                      <td>
                        <p>Compañía: {Seccion.Compania.nombre}</p>
                      </td>
                      <td>
                       
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ))
      ) : (
        <p>SIN AUSENTES</p>
      )}
    </div>
  );
};

export default ListeaAusentes;
