import { useForm } from 'react-hook-form';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import { useState } from 'react';
import swal from 'sweetalert'
import Modal from 'react-modal';
import convierteLicEsp from './convierteLicEsp.js'
import convierteSoloEstado from './convierteSoloEstado.js'


function ModalLicEsp (props) {
  const cookies=new Cookies();
  const navitate = useNavigate();
  const ID=cookies.get('id');
  const [modalCadeteIsOpen, setCadeteIsOpen] = useState(false);
  const customStyles = {
    content: {/*
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
 */
    },
  };

 
  var cierro=false;
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.net.ar/expedientes/'
  
const parametro=props.datosCadete
const usuario=ObtieneDatosCookies()
const datosCurso=props.Datos

  const store=async (data)=>{
    const client = axios.create({
      baseURL: process.env.REACT_APP_BASEURL+"cadetes/"
      });
   const respuesta=await client.post('/creaLicEsp/',convierteLicEsp(data)).then(modificaEstado(parametro,"LiEs"))
   queHago(respuesta.data).then(cierro?()=>props.myID("dale"):"")
}

  const queHago=async(respuesta)=>{

    if(respuesta.message==="Registro creado correctamente!!"){
      swal("Exito en la Licencia Especial de "+parametro.cadeteDni)
      cierro=true
    }
    else{
      swal("Algo Fallo!, consulte con el area Tecnica error A005!!!")
     
    
       }}

const modificaEstado=async(cadeteDni,estado)=>{
    const client = axios.create({
              baseURL:process.env.REACT_APP_BASEURL+"cadetes/"
              });
              try {
        
                await client.put('actualiza/'+cadeteDni.cadeteDni,convierteSoloEstado(parametro,estado))
                }
              catch (error) {}
         
        }
  const {register, formState: { errors }, handleSubmit,} = useForm({
  //recupero lo que pasa por parametros
  });
  

const categorias=devuelveCategorias()

  const licEspecial = (datillos) => {

store(datillos)
 
  }

  return (
    <>
    <div  >
   
      <form  onSubmit={handleSubmit(licEspecial)}>

<div class="row" >
<div class='visually-hidden' >

          <div class="form-floating mb-3">
                  <input value ={parametro.cadeteDni} type="text" class="form-control" id="floatingInput" placeholder="idCadete" 
         {...register('cadeteDni', {
       
          })}/>
        <label for="floatingInput">DNI Cadete</label>
    </div>
      <div class="form-floating mb-3" >
      <input value ={usuario.id}type="text" class="form-control" id="floatingNombres" placeholder="idUsuario" 
      {...register('idUsuario', {
      
      })}/>
      <label for="floatingNombres">Identificacion Usuario</label>
    </div>
    <div class="form-floating mb-3">
      <input value={usuario.sedeID}type="number" class="form-control" id="floatingUsuario" placeholder="Sede Usuario" 
      {...register('idSede', {
      
      })}/>
      <label for="floatingUsuario">Sede ID</label>
    </div>
  
</div>

<div >

          <div class='input-group-text' >
          <div class="form-floating mb-3">
  <select class="form-select" id="floatingmotivo" aria-label="motivo"
  {...register('motivo', {
    required: true
  })}
  >
   
    <option class="mb-3" value="Perdida de Aptitud Fisica/Psicologica">Perdida de Aptitud Fisica/Psicologica</option>
    <option class="mb-3" value="Embarazo">Embarazo</option>
    <option class="mb-3" value="Otro Motivo">Otro Motivo</option>   
    
    
    
  </select>
  <label for="floatingmotivo">Motivo de la Licencia</label>
</div>
<div class="form-floating mb-3">
  <input value={parametro.promocion} class="form-control" placeholder="CURSO" id="floatingCurso" 
   {...register('promocion', {
    

  })}/>
  <label for="floatingCurso">Promoción</label>
</div>
     
<div>
  
</div>
</div>

<div class='card-header mb-3' >
    
<div class="form-floating">
  <textarea class="form-control" style={{height:"250px",resize:"none"}} placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('descripcion', {
    required: true,
    maxLength:1000 
  })}></textarea>
  <label for="floatingDescripcion">Descripcion</label>
</div>    

</div>
<div class='input-group-text' >

          <div class="form-floating mb-3">
                  <input  type="text" class="form-control" id="floatingresolucionLicEsp" placeholder="resolucionLicEsp" 
         {...register('resolucionLicEsp', {
          required: true
          })}/>
        <label for="floatingresolucionLicEsp">Res. de Lic. Especial</label>
    </div>
      <div class="form-floating mb-3" style={{width:"210px"}}>
      <input type="date" class="form-control" id="floatingfechaLicEsp" placeholder="fechaLicEsp" 
      {...register('fechaLicEsp', {
        required: true
      })}/>
      <label for="floatingfechaBaja">Fecha de Res. de Lic. Especial</label>
    </div>
    
  
</div>

</div>
<div class="form-floating">
<div class='card-header mb-3' >
    

<input type="submit" value="Licenciar"  className="btn btn-success" />
<input type="button" value="Cancelar"  className="btn btn-danger" onClick={()=>props.myID("dale")}/>
</div></div></div>
 </form>

   
        
        </div>
 
        </>
  )}

export default ModalLicEsp;
