import React, { useState, useRef } from "react";
import FileUpload from "./FileUpload";
import ObtieneDatosCookies from "../obtieneDatosCookiesUsuario";
import ModalCargaImagenes from "./Modal"
import ModalEditoCadete from "./Modal"
import CadeteForm from "./CadeteForm";
import axios from 'axios';
import CameraComponent from "./Camera";
const ListeaCadetes = ({ items, actualiza}) => {
  var contador = 1;
  
  const [muestraCargaFile, setMuestroCargaFile] = useState(false)
  const [muestraEditoCadete, setMuestraEditoCadete] = useState(false)
  const [nroId, setNroId] = useState("")
  const [fotoClase, setFotoClase] = useState("")
  const [botones,setBotones]=useState(false)
  const usuario=ObtieneDatosCookies()
    

      
  const edito = (datos, funcion) => {

    setNroId(datos)
    if (funcion === 1) {
    
      setMuestraEditoCadete(true)
    } else if (funcion === 2) {
           setMuestroCargaFile(true)
      setFotoClase("foto")
  


    }
    else if (funcion === 3) {
      setMuestroCargaFile(true)
      setFotoClase("ftDni")
     
    }
    else if (funcion === 4) {
     setMuestroCargaFile(true)
      setFotoClase("bkDni")
    
    }


  }

  const borro = async(datos, funcion) => {
try{

  
  setBotones(false)
    if (funcion === 2) {
      const encodedFileName = encodeURIComponent(datos.fotoCadete);
    
      const response = await axios.delete(`https://wwwa.isepsantafe.net.ar/expedientes/borraImagen/${encodedFileName}`);
      if(response)
        var data = ""
      if (funcion == 2) {
        data = { "fotoCadete": "" }
      
      } else if (funcion ==3) {
        data = { "fotoFrenteDni": "" }
       
      } else if (funcion == 4) {
        data = { "fotoReversoDni": "" }
       
      }
      const Cadete={"Cadete":data,"Usuario":{"userID":usuario.identificacion,"cadeteDni":nroId.cadeteDni,"Operacion":"Actualiza Foto Cadete"}}
  const response2= await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/NCadetes/actualizoCadete/' + datos.cadeteDni,Cadete );
  if (response2) {
    setBotones(false)
    
    actualiza(datos)
    setMuestroCargaFile(false); // Cierra el modal
  }
  setBotones(false)
    

    }
    else if (funcion === 3) {
      var data = ""
      if (funcion == 2) {
        data = { "fotoCadete": "" }
      
      } else if (funcion ==3) {
        data = { "fotoFrenteDni": "" }
       
      } else if (funcion == 4) {
        data = { "fotoReversoDni": "" }
       
      }  const Cadete={"Cadete":data,"Usuario":{"userID":usuario.identificacion}}
  const response4 = await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/NCadetes/actualizoCadete/' + datos.cadeteDni, Cadete);
  if (response4) {
    setBotones(false)
    actualiza(datos)
    setMuestroCargaFile(false); // Cierra el modal
  }
     
      const encodedFileName = encodeURIComponent(datos.fotoFrenteDni);
      const response = await axios.delete(`https://wwwa.isepsantafe.net.ar/expedientes/borraImagen/${encodedFileName}`);
      if(response)
      
        setBotones(false)
    
    }
    else if (funcion === 4) {
      var data = ""
      if (funcion == 2) {
        data = { "fotoCadete": "" }
      
      } else if (funcion ==3) {
        data = { "fotoFrenteDni": "" }
       
      } else if (funcion == 4) {
        data = { "fotoReversoDni": "" }
       
      } 
  const response5 = await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/NCadetes/actualizoCadete/' + datos.cadeteDni, Cadete);
  if (response5) {
    setBotones(false)
    
    actualiza(datos)
    setMuestroCargaFile(false); // Cierra el modal
  }
     
      const encodedFileName = encodeURIComponent(datos.fotoReversoDni);
      const response = await axios.delete(`https://wwwa.isepsantafe.net.ar/expedientes/borraImagen/${encodedFileName}`);
      if(response)
        var data = ""
      if (funcion == 2) {
        data = { "fotoCadete": "" }
      
      } else if (funcion ==3) {
        data = { "fotoFrenteDni": "" }
       
      } else if (funcion == 4) {
        data = { "fotoReversoDni": "" }
       
      }   const Cadete={"Cadete":data,"Usuario":{"userID":usuario.identificacion,"cadeteDni":nroId.cadeteDni,"Operacion":"Actualiza Foto Cadete"}}
  const response7 = await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/NCadetes/actualizoCadete/' + datos.cadeteDni, Cadete);
  if (response7) {
    setBotones(false)
    actualiza(datos)
    setMuestroCargaFile(false); // Cierra el modal
  }
      setBotones(false)
    
      
    }
}catch{
  var data = ""
      if (funcion == 2) {
        data = { "fotoCadete": "" }
      
      } else if (funcion ==3) {
        data = { "fotoFrenteDni": "" }
       
      } else if (funcion == 4) {
        data = { "fotoReversoDni": "" }
       
      }
      const Cadete={"Cadete":data,"Usuario":{"userID":usuario.identificacion,"cadeteDni":nroId.cadeteDni,"Operacion":"Actualiza Foto Cadete"}}
  const response = await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/NCadetes/actualizoCadete/' + datos.cadeteDni, Cadete);
  if (response) {
    setBotones(false)
    
    actualiza(datos)
    setMuestroCargaFile(false); // Cierra el modal
  }
 
  
  

}
  


  }





  const truncarTexto = (texto, limite) => {
    return texto.length > limite ? texto.substring(0, limite - 3) + "..." : texto;
  };

  const handleFileUploaded = (fileName) => {
  
    actualizaFoto(fotoClase,fileName)

  };
  const handleCloseModal = (nombre) => {

    
      

  };

  const manejaCierroEditor = (value) => {

    value ? actualizaCadete(value) : console.log("")
    setMuestraEditoCadete(false)
  }

  const actualizaFoto = async (tipo,valor) => {
    try {

      var data = ""
      if (tipo == "foto") {
        data = { "fotoCadete": valor }
        console.log(tipo,valor)
        actualizaCadete(data)
      } else if (tipo == "ftDni") {
        data = { "fotoFrenteDni": valor }
        actualizaCadete(data)
      } else if (tipo == "bkDni") {
        data = { "fotoReversoDni": valor }
        actualizaCadete(data)
      }
    } catch { }
  }
  const actualizaCadete = async (data) => {
    try {
      setBotones(true)
      const dataCadete = data
      var operacion=""
      if (data.cadeteDni){
        if(data.cadeteCondicion=="Convocado"){
  dataCadete.cadeteCondicion = "Propedeutico"
          operacion="Actualiza Cadete"
        }else{
dataCadete.cadeteCondicion = "Regular"
          operacion="Actualiza Cadete"
        }
        
      }else{
operacion="Sube Foto Cadete"
      }

const Cadete={"Cadete":data,"Usuario":{"userID":usuario.identificacion,"cadeteDni":nroId.cadeteDni,"Operacion":operacion}}
    
      const response = await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/NCadetes/actualizoCadete/' + nroId.cadeteDni, Cadete);
      if (response) {
        setBotones(false)
        actualiza(nroId)
        setMuestroCargaFile(false); 
        
      }

    } catch (error) {
      setBotones(false)
      console.error('Error en la petición', error);
    }



  };

const imprime=(dni)=>{
  window.open('/imprimeReempadronamiento?dni='+dni)
}


function contarCadetesPorCondicion(cadetes, condicion) {
  return cadetes.filter(cadete => cadete.cadeteCondicion === condicion).length;
}
const cantidadReempadronar = contarCadetesPorCondicion(items, 'Reempadronar');
const cantidadRegular = contarCadetesPorCondicion(items, 'Regular');
const cantidadConvocados = contarCadetesPorCondicion(items, 'Convocados');
const cantidadPropedeutico = contarCadetesPorCondicion(items, 'Propedeutico');

  return (
    
    <>{muestraEditoCadete ? <>

      <ModalEditoCadete isOpen={muestraEditoCadete} onClose={manejaCierroEditor}>
        <div>

          <CadeteForm cadete={nroId} onClose={(valor) => manejaCierroEditor(valor)} />

        </div>
      </ModalEditoCadete>
    </> : ""}

      {muestraCargaFile ? <>

        <ModalCargaImagenes isOpen={muestraCargaFile} onClose={() => setMuestroCargaFile(false)}>
          <div>

          <CameraComponent cadete={nroId} foto={fotoClase} onClose={handleCloseModal} onFileUploaded={handleFileUploaded}/>
          <hr/>
           <FileUpload onFileUploaded={handleFileUploaded} onClose={handleCloseModal} cadete={nroId} foto={fotoClase} myID={(nombreArchivo) => console.log(nombreArchivo)} />
           
          </div>
        </ModalCargaImagenes>
      </> : ""}
 
      <a >Cantidad a Reempadronar {cantidadReempadronar} - Reempadronados {cantidadRegular} </a>
<div style={{display:"flex",justifyContent:"space-around"}}>

<div style={{ gap: "10px", maxWidth: "80%", overflowX: "auto",textAlign:"center" }}>

  <table style={{ width: "100%", borderCollapse: "collapse" }}>
    <thead>
      <tr>
      
        <th style={{ textAlign: "center" , width:"90px"}}>Promocion</th>
        <th style={{ textAlign: "center" , width:"250px"}}>Cadete</th>
        <th style={{ textAlign: "center" , width:"100px"}}>Dni:</th>
        <th style={{ textAlign: "center" , width:"50px"}}>Condición:</th>
        <th style={{ textAlign: "center" }}>Foto Frente:</th>
        <th style={{ textAlign: "center" }}>Foto DNI F:</th>
        <th style={{ textAlign: "center" }}>Foto DNI R:</th>
        {usuario.destino == 7 ? <th style={{ textAlign: "center" }}>Editar</th> : ""}
      </tr>
    </thead>
    <tbody>
      {items.map((cadete, index) => {
        let cadeteColor = "";

        return (
        
          <tr
            key={index}
            style={{
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              textAlign: "left",
            }}
          >
  
            <th style={{   
             
              textAlign: "center",}} >{cadete.Promocions.nombre?cadete.Promocions.nombre=="2025-2026"?<a style={{color:"red"}}>Ingreso</a>:"2024-2025":cadete.Promocions[0].nombre?cadete.Promocions[0].nombre=="2025-2026"?<a style={{color:"red"}}>Ingreso</a>:"2024-2025":"2024-2025"}</th>
            <th style={{ color: cadeteColor }}>
          
            {truncarTexto(`${cadete.cadeteApellido} ${cadete.cadeteNombre}`, 26)}
            </th>
            <th>{cadete.cadeteDni}</th>
            <th>
              {cadete.cadeteCondicion === "Convocado" ||cadete.cadeteCondicion === "Reempadronar"? (
                <a
                  onClick={() => edito(cadete, 1)}
                  style={{
                    color: "red",
                    border: "1px solid red",
                    textAlign: "center",
                  }}
                >
                  Reempadronar
                </a>
              ) : (
                <a onClick={()=>imprime(cadete.cadeteDni)}
                  style={{
                    textAling:"center",
                    color: "green",
                    border: "1px solid green ",
                    borderRadius: "5px",
                    display:"flex",
                    justifyContent:"space-around"
                  }}
                >
                 {cadete.cadeteCondicion}
                </a>
              )}
            </th>
            <th style={{ textAlign: "center" }}>
              {cadete.fotoCadete ? usuario.destino==7?(
                <button
                  className="btn btn-warning"
                  disabled={botones}
                  onClick={() => borro(cadete, 2)}
                >
                  Borrar
                </button>
              ):(
                <button
                  className="btn btn-warning"
                  disabled={true}
                  onClick={() => borro(cadete, 2)}
                >
                  Borrar
                </button>
              ) : (
                <button className="btn btn-success" onClick={() => edito(cadete, 2)}>
                  Sacar
                </button>
              )}
            </th>
            <th>
              {cadete.fotoFrenteDni ? usuario.destino==7?(
                <button className="btn btn-warning"   onClick={() => borro(cadete, 3)}>
                  Borrar
                </button>
              ) :(
                <button className="btn btn-warning"  disabled={true} onClick={() => borro(cadete, 3)}>
                  Borrar
                </button>
              ) : (
                <button className="btn btn-success" onClick={() => edito(cadete, 3)}>
                  Sacar
                </button>
              )}
            </th>
            <th>
              {cadete.fotoReversoDni ?usuario.destino==7? (
                <button className="btn btn-warning" onClick={() => borro(cadete, 4)}>
                  Borrar
                </button>
              ) :(
                <button className="btn btn-warning" disabled={true} onClick={() => borro(cadete, 4)}>
                  Borrar
                </button>
              ) : (
                <button className="btn btn-success" onClick={() => edito(cadete, 4)}>
                  Sacar
                </button>
              )}
            </th>
            <th>
              {usuario.destino == 7 && cadete.cadeteCondicion !== "Convocado" ? (
                <button className="btn btn-dark" onClick={() => edito(cadete, 1)}>
                  Editar
                </button>
              ) : (
                ""
              )}
            </th>
          </tr>
        );
      })}
    </tbody>
  </table>
</div>

</div>
    </>
  );
};

export default ListeaCadetes;
