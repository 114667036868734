import React, { useEffect, useState } from 'react';
import axios from 'axios';
import regresaFecha from '../tools/regresaBienFechayDia'
const FileList = (props) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchFiles = async () => {
    try {
      const response = await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/archivos');
      setFiles(response.data);
    } catch (err) {
      setError('Error al cargar los archivos');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    

    fetchFiles();
  }, [props.enviar]);

  if (loading) {
    return <p>Cargando archivos...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }
const funcionBorra=async(filename)=>{

  setLoading(true);
  const encodedFileName = encodeURIComponent(filename);
  alert(encodedFileName)
  const response = await axios.delete(`https://wwwa.isepsantafe.net.ar/expedientes/borraArchivoPrivado/${encodedFileName}`);
 

  fetchFiles()


}
  return (
    <div>
      <h2>Lista de Archivos</h2>
      <ul>
        {files.map((file) => (
          <li key={file.name}>
            <a href={`https://wwwa.isepsantafe.net.ar/uploads/${file.name}`} target="_blank" rel="noopener noreferrer">
              {file.name} - {(file.size / (1024 * 1024)).toFixed(2)} - {"subido el " +regresaFecha(file.createdAt)}
            </a><button onClick={()=>funcionBorra(file.name)} class="btn btn-danger">X</button > 
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;
