import React , { useState }from "react";
import {useNavigate,useParams} from 'react-router-dom';
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
import DevuelveCursos from '../cadetes/devuelveCursos'
import DevuelveCadetes from '../cadetes/devuelveCadetes'
import Modal from 'react-modal';
import Loguin from './formEditaReincorporacion'
import EdicionCadete from './formEditaCadete'
import CargaMasiva from '../tools/EnvioMasivo'
import { UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,Badge } from "reactstrap";
    import { CSVLink } from "react-csv";
import axios from 'axios';


function Cadetes () {
  const Usuario=obtieneDatos();
  if(!Usuario.id){
    alert("Acceso no Autorizado")
     window.location.href='./';
  }
  const customStyles = {
    content: {
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
 
    },
  };

  function openModalCadete() {
    setCadeteIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }
  function closeModalCadete() {
    setCadeteIsOpen(false);
    muestraCadetes(cursosSeleccionado,"Todas")
  }
  function closeModalReincorporaciones() {
    setReincorporacionIsOpen(false);
    muestraCadetes(cursosSeleccionado,"Todas")
  }

  function openModalReincorporacion() {
    setReincorporacionIsOpen(true);
  }
  function openCadeteModal() {
    setCadeteIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }
  function closeModalReincorporaciones() {
    setReincorporacionIsOpen(false);
    muestraCadetes(cursosSeleccionado,"Todas")
  }







  function openModalIncorporacion() {
    setIncorporacionIsOpen(true);
  }


  function closeModalIncorporacion() {
    setIncorporacionIsOpen(false);
    muestraCadetes(cursosSeleccionado,"Todas")
  }

    const navitate = useNavigate()
    const[cadetes,setCadetes]=useState({}) 
    const[cursosDisponibles,setCursosDisponibles]=useState({}) 
    const[muestroCursos,setMuestroCursos]=useState(false)
    const[cursos,setCursos]=useState(false)
    const[cursosSeleccionado,setCursosSeleccionado]=useState("")
    const[muestraCantidadCadetes,setMuestraCantidadCadetes]=useState(false)
    const[muestroCadetes,setMuestroCadetes]=useState(false)
    const[cantidadCadetes,setCantidadCadetes]=useState(0)
    const[condicion,setcondicion]=useState("Todas")
    const [modalCadeteIsOpen, setCadeteIsOpen] = useState(false);
    const [modalReincorporacionIsOpen, setReincorporacionIsOpen] = useState(false);
    const [modalIncorporacionIsOpen, setIncorporacionIsOpen] = useState(false);
    const [modalCursoIsOpen, setCursoIsOpen] = useState(false);
    const [cadeteSeleccionado, setCadeteSeleccionado] = useState({});
    const [estadoCadete, setEstadoCadete] = useState({});
    const [sedeConsulta,setSedeConsultada]=useState("TODAS")


const muestrasCursos=async()=>{
  setMuestroCursos(false)
  const client = axios.create({
      baseURL:process.env.REACT_APP_BASEURL+"cadetes/muestraPromociones"
      });
      try {
        await client.post('/').then(f=>setCursos(f.data)).then(setMuestroCursos(true))
        }
      catch (error) {}
 
}
const crearNuevaPromocion=async(promocion)=>{
  const client = axios.create({
      baseURL:process.env.REACT_APP_BASEURL+"cadetes/crearPromocion"
      });
      try {
        await client.post('/',promocion).then((alert("Curso "+promocion.curso+" creado correctamente"))).then(setMuestroCursos(true)).then(actualizaCursos)
       
        }
      catch (error) {}
 
}
const actualizaCursos=()=>{
  setCursos(false)
  setMuestroCadetes(false)
  setMuestroCursos(false)
  
}
const devuelveEstadoCadete=async(datosCadete)=>{

  const client = axios.create({
      baseURL:process.env.REACT_APP_BASEURL+"cadetes"
      });
      try {
       

 await client.post("/consultaEstados/"+datosCadete.cadeteDni,{"promocion":datosCadete.promocion}).then(f=>setEstadoCadete(f.data))
        }
      catch (error) {console.error(error)}
 
}

const muestraCadetes=async(datillo,condicion)=>{

  const client = axios.create({
      baseURL:process.env.REACT_APP_BASEURL+"cadetes"
      });
      try {
        
        if(condicion==="Todas"){

         const cadetesDevueltos= await client.post("/",{"promocion":datillo.api})
          if(cadetesDevueltos){
            cadetesDevueltos.data.sort(function (a, b) {
              return a.cadeteApellido.localeCompare(b.cadeteApellido);
            })
            setCadetes(cadetesDevueltos.data)
           
            setMuestroCadetes(true)
          }
        }else if(condicion==="BAJA Y LiEs"){
          const cadetesBajas =await client.post('/consulta/'+"BAJA",{"promocion":datillo.api})
         
          
          if(cadetesBajas ){
            
            const cadetesLies =await client.post('/consulta/'+"LiEs",{"promocion":datillo.api})
            if(cadetesLies)
              Uno(cadetesBajas.data,cadetesLies.data)
           
            }
          
        }else{
           const cadetesDevueltos =await client.post('/consulta/'+condicion,{"promocion":datillo.api})
           if(cadetesDevueltos){
            cadetesDevueltos.data.sort(function (a, b) {
              return a.cadeteApellido.localeCompare(b.cadeteApellido);
            })
            setCadetes(cadetesDevueltos.data)

            setMuestroCadetes(true)

           }
        }
      
       

        }
      catch (error) {}
 
}

const Uno=(array1,array2)=>{
 
  var arregloMezclado=[]
  console.log(array1)
  
  console.log(array2)
  const arrayUnido = [...array1, ...array2];
  arrayUnido.sort(function (a, b) {
    return a.cadeteApellido.localeCompare(b.cadeteApellido)})
    setCadetes(arrayUnido)

    setMuestroCadetes(true)

}
const headers = [
  { label: "Nro Control", key: "id" },
  { label: "DNI", key: "cadeteDni" },
  { label: "Nombres", key: "cadeteNombre" },
  { label: "Apellido", key: "cadeteApellido" },
  { label: "Genero", key: "cadeteGenero" },
  { label: "Sede", key: "cadeteSede" },
  { label: "Estado", key: "cadeteEstado" },
  {label:"Domicilio",key:"cadeteDomicilio"},
  {label:"Ciudad",key:"cadeteCiudad"},
  {label:"U.R.",key:"cadeteUR"},
  {label:"Telefono",key:"cadeteCelular"},

];
const cambioSede=e=>{
 
    setSedeConsultada(e.target.value);

    
}

const devuelveCurso=(curso)=>{
  setCursosSeleccionado(curso)
    return curso
  }
const modificoEstadoCadetes=async(cadeteAmodificar)=>{
  

  await devuelveEstadoCadete(cadeteAmodificar).then(setCadeteSeleccionado(cadeteAmodificar)).then(openModalCadete(true))

   
}

const volver=()=>{
    navitate('/usuarioLogueado')
}
if(!muestroCursos){
  muestrasCursos()

}
const cambioCondicion=async e=>{
setcondicion(e.target.value)

muestraCadetes(cursosSeleccionado,e.target.value)

}
const incorporarCadetes=()=>{
  openModalIncorporacion()
  alert("incorporando a " + cursosSeleccionado.curso)
}
const crearPromocion=()=>{
  const valor = window.prompt("Ingrese el nombre del curso")
  
  const ultimaLetra=valor.substring(valor.length - 1)
  
  if(ultimaLetra!='s'){
    const promocion={
      
      curso:"Curso "+valor,
      escuela:"Escuela de Policia",
      api:valor+"s",
      resolucion:valor,
      estado:"Activo",
      nombre:valor
    
    
    }
      crearNuevaPromocion(promocion)
  }
else{alert("no puede terminar en la letra s")}

}
    return(
        <>
         <div >

         <div>
         <Modal
        isOpen={modalIncorporacionIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalIncorporacion}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalIncorporacion}>x </button> <a style={{alignSelf:"center"}}><strong>Incorporacion Masiva:{cursosSeleccionado.api}</strong></a>
      </div>
       
         <CargaMasiva Datos={cursosSeleccionado}  myID={(value2)=>closeModalIncorporacion()}/>
         
      </Modal>
      <Modal
        isOpen={modalReincorporacionIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalReincorporaciones}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalReincorporaciones}>x </button> <a style={{alignSelf:"center"}}><strong>Reincorporaciones: {cursosSeleccionado.api}</strong></a>
      </div>
       
         <Loguin Datos={cursosSeleccionado} myID={(value2)=>closeModalReincorporaciones()}/>
         
      </Modal>
      <Modal
        isOpen={modalCadeteIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalCadete}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalCadete}>x </button> <a style={{alignSelf:"center"}}><strong>Edicion del Cadete: {cadeteSeleccionado.cadeteDni}</strong></a>
      </div>
       
         <EdicionCadete datosCadete={cadeteSeleccionado} estadoCadete={estadoCadete} esperoCerrar={(value2)=>closeModalCadete()}/>
         
      </Modal>
    </div>

          </div>
<div style={{textAlign:"center", backgroundColor:"rgb(243 243 243)", margin:"5px"}}>
           <h3 >Menu de Gestion de Cadetes</h3> 
           <h4>Usuario logueado:{Usuario.nombre}</h4>
           <div className="btn-group">
           <button className="btn btn-warning" onClick={volver}>Volver</button>
           {Usuario.destino=="7"?<button className="btn btn-primary" onClick={crearPromocion}>Crear Promocion</button>:""}
           </div>
           
           </div>
           <div style={{ color:"white",textAlign:"center", maxWidth:"400",minHeight:"600",backgroundColor:"#aaa", margin:"5px"}}>
<h3 >Cursos Activos</h3>

{muestroCursos?(<>
<div style={{backgroundColor:"grey"}}>
<DevuelveCursos cosas={cursos} myID={(value)=>muestraCadetes(devuelveCurso(value),condicion)} />

</div>

</>):""}
<div className="btn-group"style={{backgroundColor:"palegreen",padding:"3px"}}>
<UncontrolledDropdown>
  <DropdownToggle
    caret
    color="dark"
  >
  {sedeConsulta}
  </DropdownToggle>
 
   
  <DropdownMenu dark>
   
    <DropdownItem     value="ROSARIO" 
   
    onClick={cambioSede}>
     ROSARIO
    </DropdownItem>
    <DropdownItem     value="RECREO" 
   
    onClick={cambioSede}>
     RECREO
    </DropdownItem>
    <DropdownItem     value="RECONQUI" 
   
    onClick={cambioSede}>
     RECONQUISTA
    </DropdownItem>
    <DropdownItem     value="RAFAELA" 
   
    onClick={cambioSede}>
     RAFAELA
    </DropdownItem>
    <DropdownItem     value="MURPHY" 
   
    onClick={cambioSede}>
     MURPHY
    </DropdownItem>
    <DropdownItem divider />
    <DropdownItem     value="TODAS" 
   
   onClick={cambioSede}>
    TODAS
   </DropdownItem>

   
  </DropdownMenu>
</UncontrolledDropdown>
<UncontrolledDropdown>
  <DropdownToggle
    caret
    color="dark"
  >
      Condicion:{" "+condicion}
  </DropdownToggle>
 
  <DropdownMenu dark>
   
    
    <DropdownItem     value="Regular" 
   
   onClick={cambioCondicion}>
    REGULAR
   </DropdownItem>
    <DropdownItem     value="BAJA Y LiEs" 
   
   onClick={cambioCondicion}>
     BAJAS Y LiEs
   </DropdownItem>
    <DropdownItem     value="LTB" 
   
    onClick={cambioCondicion}>
     L.T.B
    </DropdownItem>
    <DropdownItem     value="BAJA" 
   
    onClick={cambioCondicion}>
     BAJA
    </DropdownItem>
    
    <DropdownItem     value="LiEs" 
   
    onClick={cambioCondicion}>
     LICENCIA ESPECIAL
    </DropdownItem>
    <DropdownItem     value="Propedeutico" 
   
    onClick={cambioCondicion}>
     Propedeutico
    </DropdownItem>
    <DropdownItem     value="Ausente" 
   
   onClick={cambioCondicion}>
    Ausente
   </DropdownItem>
   <DropdownItem     value="Egresado" 
   
    onClick={cambioCondicion}>
     EGRESADO
    </DropdownItem>
    <DropdownItem     value="Todas" 
   
   onClick={cambioCondicion}>
   Todas
   </DropdownItem>

  </DropdownMenu>
</UncontrolledDropdown>

</div>
{muestroCadetes?(
  Usuario.destino==="7"?(<button className="btn btn-danger" onClick={openModalReincorporacion}>Reincorporar</button>):""):""}
  {muestroCadetes?(
  Usuario.destino==="7"?(<button className="btn btn-warning" onClick={incorporarCadetes}>Incorporar Cadetes</button>):""):""}



{muestroCadetes&&cadetes[0]&&(<>
 
<div style={{display:"flex-container", justifyContent:"space-between",margin:"10px"}}>
  
  <div style={{height:"450px",overflow:"scroll"}}>
<DevuelveCadetes cosas={sedeConsulta==="TODAS"?cadetes:cadetes.filter(x=>x.cadeteSede==sedeConsulta)} myID={(value2)=>modificoEstadoCadetes(value2)} />
</div>


  

    </div>


</>)}

           </div>
           
          
        </>
    )
}
export default Cadetes