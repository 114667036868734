import React, { useState,useEffect } from 'react';
import {Document, Page, View,Text, PDFViewer,Image, StyleSheet, pdf} from '@react-pdf/renderer';
import ImagenQR from '../tools/imagenQR.jsx';
import Logo from '../certificados/image.png'
import logoProvincia from '../certificados/image.png'
import front from './front.jpg'
import back from './back.jpg'
import textoIsep from '../certificados/logoProvinciaISeP2024.png'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';
import regresaBienFecha from '../tools/regresaBienFecha.js'

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return windowSize;
  };
  function Reempadronamiento() {
    
    function formatToThreeDigits(number) {
      if (number < 1 || number > 100) {
       
         return number
      }
      return number.toString().padStart(3, '0');
  }
    const { width, height } = useWindowSize();
    function useQuery() {
      return new URLSearchParams(useLocation().search);
    }
    const styles = StyleSheet.create({
      text: {
        fontFamily: 'Tahoma',
        fontSize: 12,
      },
    });
    const [muestro,setMuestro]=useState(false)
    const [Er,setEr]=useState(false)
    const [datosVarios,setDatosVarios]=useState(false)
    const [claseCertificad,setClaseCertificado]=useState()
    const [promocion,setPromocion]=useState('')

    const consulta = useQuery();

   
     

     
      
   


  function fechaFormal() {
    const meses = [
      "enero", "febrero", "marzo", "abril", "mayo", "junio", 
      "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];

    const hoy = new Date();
    const dia = hoy.getDate();
    const mes = meses[hoy.getMonth()];
    const anio = hoy.getFullYear();
  //a los ${dia} días del mes de 
    return `${mes} de ${anio}`;
  }
 
  
  const token = btoa(`${process.env.REACT_APP_KEY}:${"password"}`); // Codifica en Base64
  const obtieneCertificado=async()=>{
    const client = axios.create({
        baseURL: process.env.REACT_APP_BASEURL+"NCadetes/devuelveCadete/"+consulta.get("dni"),headers: {
          Authorization: `Basic ${token}` // Agrega el encabezado Authorization de tipo Basic
      }
        });
        try {
         const datos= await client.post('/')
         if(datos)
          { console.log(datos.data.datos[0]
            
          )
         setPromocion(datos.data.datos[0].Promocions.nombre)
       if(datos.data.Cursante!="Nada que mostrar"){

      setDatosVarios(datos.data.datos[0])

        setMuestro(true)
       }else{
        setEr(true)
       }
        

         }else{setEr(true)}
          }
        catch (error) {}
   
  }
  

  
  useEffect(() => {
  
    obtieneCertificado()
    return () => {
      
    };
  }, []);
  
  
  let numero=datosVarios.cadeteDni;
  let numeroComoString = numero?.toString();
  
    

  const hoy=fechaFormal()

 
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    
    // Esta función se ejecuta cuando el PDF se carga correctamente
    function onLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
  const text4=`${datosVarios.cadeteSede}, ${hoy+""}`
const credencial=
<View id="credencial"style={{ position:"absolute",  padding:"1px"}}>


<Image style={{width:"150px", position:"absolute",marginLeft:"140px", marginTop:"560px" }}src={front}/> 

<Image style={{width:"150px",  position:"absolute", marginLeft:"299px",marginTop:"560px"}} src={back}/>
<Image style={{objectFit: "cover",width:"80px", height:"80px", marginTop:"608px",   borderRadius: 1222, transform:'rotate(360deg)',marginLeft:"174px"}} src={`https://wwwa.isepsantafe.net.ar/imagenes/${datosVarios.fotoCadete}`}/>

<View id="QR"style={{width:"160px", position:"relative",  marginLeft:"135px", textAlign:"center"}}>    
<Text style={{paddingTop:"1px",fontSize:"12px",lineHeight: "1", marginTop:"8px",color:"#fff"}}>{datosVarios.cadeteApellido} {datosVarios.cadeteNombre}</Text>

<Text style={{paddingTop:"1px",fontSize:"8px",lineHeight: "1", marginTop:"6px", color:"#111"}}>C A D E T E ISeP</Text>
<Text style={{paddingTop:"1px",fontSize:"10px",lineHeight: "1", marginTop:"2px", color:"#fff"}}>{datosVarios.cadeteCondicion}</Text>
<Text style={{paddingTop:"1px",fontSize:"13px",lineHeight: "1", marginTop:"5px",color:"#fff"}}>{datosVarios.cadeteDni}</Text>  
<Text style={{paddingTop:"1px",fontSize:"8px",lineHeight: "1", marginTop:"5px",color:"#fff"}}>{promocion}</Text> 
</View>

<Text style={{textAlign:"center",paddingTop:"1px",fontSize:"8px",lineHeight: "2", marginTop:"-71px", marginLeft:"325px",color:"black"}}>{datosVarios.cadeteApellido} {datosVarios.cadeteNombre}</Text>
<Text style={{textAlign:"center",paddingTop:"1px",fontSize:"8px",lineHeight: "2", marginTop:"63px", marginLeft:"325px",color:"black"}}>Firma Director Esc. Policia</Text>
<View id="QR"style={{width:"150px", position:"absolute", marginLeft:"150px",marginTop:"563px"}}>       
<ImagenQR direccion={numeroComoString} tamaño="110"/></View>
<View id="QR"style={{width:"150px", position:"absolute", marginLeft:"333px",marginTop:"595px"}}>       
<ImagenQR direccion={numeroComoString} tamaño="250"/></View>{console.log(datosVarios.cadeteDni)}
</View>

  const clase1 = () => (
    <Document  title={"Planilla de Reempadronamiento "+datosVarios.cadeteDni + " "+ datosVarios.cadeteApellido}   // La ruta del archivo PDF
    onLoadSuccess={onLoadSuccess}  // Para obtener el número de páginas
    renderMode="svg"  >
          <Page size="A4" >
            
          <View style={{ width:"100%", marginTop:"20px",marginLeft:"10px",padding:"1px"}}>
          <View id="Logos"style={{ width:"100%",   padding:"1px"}}>
         
          <Image style={{width:"52px", position:"absolute",marginLeft:"25px", marginTop:"-10px" }}src={logoProvincia}/> 
         
          <Image style={{width:"100px", position:"absolute", marginTop:"10px", marginLeft:"450px"}} src={textoIsep}/>

        
         </View>
         <Text style={{textAlign:"center",marginTop:"30px",paddingTop:"1px",fontSize:"22px",fontWeight:"bold"}}>Reepadronamiento Cadetes ISeP</Text>
         <Text style={{textAlign:"center",paddingTop:"1px",fontSize:"15px",fontWeight:"bold"}}> Promocion {datosVarios.Promocions.nombre}</Text>
    
          <View className='datosPersonales_legajo' style={{textAlign:"justify",marginLeft:"25px",marginRight:"35px",paddingTop:"1px",fontSize:"12px",lineHeight: "1"}}>
  

      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", marginTop:"40px", color:"#333"}}>Apellido y Nombre <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteApellido} {datosVarios.cadeteNombre}</Text>
      </Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333",marginTop:"2px"}}>DNI: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteDni} </Text> CUIL: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteCuil}</Text> Fecha de Nacimiento: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{regresaBienFecha(datosVarios.fechaNacimiento)}</Text>
      </Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333",marginTop:"2px"}}>Nombre y Apellido Padre: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadetePadre} </Text></Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333",marginTop:"2px"}}>
      Nombre y Apellido Madre: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteMadre}</Text> 
      </Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333",marginTop:"2px"}}>
      Domicilio: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteDomicilio}</Text>    Localidad: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteCiudad}</Text> 
      </Text>

      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333",marginTop:"2px"}}>
      Departamento: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteUR}</Text>   Comisaria: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteCria}</Text>  
      </Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333",marginTop:"2px"}}>
      Gupo Sanguineo: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteGrupoSanguineo}</Text>   Genero: <Text style={{textAlign:"justify",fontSize:"14px", color:"black"}}>{datosVarios.cadeteGenero}</Text>  
      </Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333",marginTop:"2px"}}>
       <Text style={{textAlign:"justify",fontSize:"20px", color:"black"}}>--------------------------------------------------------------------------------</Text>  
      </Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333"}}>
      Correo Electrónico: <Text style={{textAlign:"justify",fontSize:"16px", color:"black"}}>{datosVarios.cadeteEmail}</Text>   
      </Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "1", color:"#333"}}>
   Telefono: <Text style={{textAlign:"justify",fontSize:"20px", color:"black"}}>{datosVarios.cadeteCelular}</Text>  
      </Text>
      <Text style={{textAlign:"justify",paddingTop:"1px",fontSize:"12px",lineHeight: "2", color:"#333"}}>
       <Text style={{textAlign:"justify",fontSize:"20px", color:"black"}}>---------------------------------------------------------------------------------</Text>  
      </Text>
      <Text style={{ width:"100%",textAlign:"justify",paddingTop:"1px",fontSize:"13px",lineHeight: "2", color:"#333"}}>
      Declaro y acepto que los datos consignados corresponden a los declarados anteriormente, como asi tambien que la direccion de correo electrónico mencionada es en la cual donde deseo recibir las notificaciones durante el presente cursado.</Text>
      <Text style={{ width:"100%",textAlign:"rigth",paddingTop:"1px",fontSize:"12px",lineHeight: "2", color:"#333"}}>
      {text4}</Text>
      <Text style={{ width:"100%",textAlign:"right",marginTop:"40px",fontSize:"12px",lineHeight: "1", color:"#333"}}>
      ..............................</Text>
      <Text style={{ width:"100%",textAlign:"right",paddingTop:"1px",fontSize:"9px",lineHeight: "1", color:"#333"}}>
      {datosVarios.cadeteApellido +", "+datosVarios.cadeteNombre}</Text>
      <Text style={{ width:"100%",textAlign:"right",paddingTop:"1px",fontSize:"9px",lineHeight: "1", color:"#333"}}>
      {datosVarios.cadeteDni}</Text>


      <Text style={{textAlign:"justify",fontSize:"9px", marginLeft:"-10px",color:"black"}}>---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>  
 

    
    
      
      
      
     
      </View>
      {credencial}
      
      </View>

          </Page>
        </Document>
  );
  

 
  var MyDocument = clase1;

 

    const generatePdfDocument = async () => {
     try {
      const nombreArchivo="Certificado "+datosVarios.tituloCertificado + " "+ datosVarios.apellidoNombre
      const doc =<MyDocument/>
      const blob = await pdf(doc).toBlob();
      saveAs(blob, nombreArchivo);
     } catch (error) {
      
     } 
    };
  // Componente principal del visor PDF
  return (<>
  {<>
  {muestro?<> {width<480?<><div style={{textAlign:"center"}}>
          <img src={Logo} style={{width:"100px"}}></img>
          <h1>Descargue su certificado {datosVarios.apellidoNombre}</h1>
          <button className="btn btn-primary"onClick={generatePdfDocument}>DESCARGAR</button></div></>:
          <>
          <PDFViewer showToolbar="false" style={{width:"100%",height:"100vh"}} >
        <MyDocument />
        </PDFViewer></>}</>:<>{Er?<><div style={{ textAlign: 'center', margin:"10px", border:"1px solid red", padding:"5px"}}><img src={logoProvincia} width="120px" alt="Escudo" />
            <h1 style={{ textAlign: 'center', marginTop: '10px', color:"red"}}>{"Error al obtener los datos o datos Invalidos"}</h1>
            <h7>comuniquese al area de Extención comunitaria "extensioncomunitaria@isepsantafe.net.ar" si considera que es un error  </h7></div></>:""}</>}
    
       
  
    
  </>}
  
        
        </>
  
      );
    }
  export default Reempadronamiento
  