function FooterCorp () {
    
    return (
      <div>
       
       <footer>
        <div style={{fontSize:"11px"}}>
          <h7>DTyD 2025 sistemadegestion@isepsantafe.net.ar</h7>
        </div>
      </footer>
       
        
      </div>
    );
  };
  export default FooterCorp;
  