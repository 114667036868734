import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ListeaCadetes from './ListeaCadetes';
import socket from '../tools/socke'; 

const ObtienePromociones = () => {
  const [listadoPromocion, setListadoPromocion] = useState([]);
  const [visible, setVisible] = useState(false);
  const [listaObtenida, setListaObtenida] = useState("");
  const [filtro, setFiltro] = useState("");
  const [cadetesFiltrados, setCadetesFiltrados] = useState([]);
  const [promocionSeleccionada,setPromocionSeleccionada]=useState("")
const [cargando,setCargando]=useState(false)
  useEffect(() => {
    socket.on('estadoActualizable', (listaObtenidaa) => {
      console.log(listaObtenidaa.Cadetes.filter(x=>x.cadeteEstado=="Activo"))
     setCadetesFiltrados(listaObtenidaa.Cadetes.filter(x=>x.cadeteEstado=="Activo"))
     
    });
    // Limpiar socket al desmontar el componente
    return () => {
      socket.off('estadoActualizable');
    };
  }, []);

  const getPromociones = async (dato) => {
    try {
      if(dato!=promocionSeleccionada){
        setPromocionSeleccionada(dato)
        setCargando(true)
        setVisible(false);
      }else{
      
        setCargando(true)

      }
     
      const response = await axios.post(
        `https://wwwa.isepsantafe.net.ar/expedientes/devuelveCadetesPorPromocion/${dato}`
      );
      console.log(response.data.Cadetes)

      setCadetesFiltrados(response.data.Cadetes); // Sincronizar cadetesFiltrados con el nuevo listado
    } catch (error) {
      console.error('Error en la petición', error);
    }
    setVisible(true);
    setCargando(false)
  };



  const enciendeLectores = () => {
    console.log(promocionSeleccionada)
    getPromociones(promocionSeleccionada)
   socket.emit('reempadrone', promocionSeleccionada);

  };

  const filtrarCadetes = (cadetes, texto) => {
    if (!texto.trim()) return cadetes; // Si no hay filtro, devolver todos los cadetes
    if (!isNaN(texto)) {
      // Si el texto es un número, filtra por cadeteDni
      return cadetes.filter(item => item.cadeteDni.toString().startsWith(texto));
    } else {
      // Si no es un número, filtra por cadeteApellido
      return cadetes.filter(item => item.cadeteApellido.toLowerCase().startsWith(texto.toLowerCase()));
    }
  };


  return (
    <div>
      <div>
        <h4>Promociones Activas</h4>
        <button className='btn btn-secondary' onClick={() => getPromociones("2024-2025")}>2024-2025</button>
        <button className='btn btn-secondary' onClick={() => getPromociones("2025-2026")}>2025-2026</button>
      </div>
      <div>
       {cargando?"cargando Lista....":""}
      </div>
      {visible && cadetesFiltrados.length > 0 && (
        <ListeaCadetes
          actualiza={enciendeLectores}
          items={cadetesFiltrados}
          filtro={filtro}
        />
      )}
    </div>
  );
};

export default ObtienePromociones;
