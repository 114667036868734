import React, { useState } from 'react';
import axios from 'axios';
import ListeaAusentes from './listeaAusentes'
const FechaSelector = () => {
  const [fecha, setFecha] = useState("");
  const [ausentes, setAusentes] = useState("")

  const handleFechaChange = async (e) => {
    const nuevaFecha = e.target.value;  // Obtiene la nueva fecha seleccionada
    setFecha(nuevaFecha);  // Actualiza el estado con la nueva fecha


    if (nuevaFecha) {
      try {

        const response = await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/devuelveParteAusentes/' + nuevaFecha);
        console.log('Respuesta del servidor:', response.data);
        setAusentes(response.data.ausentes)
      } catch (error) {
        console.error('Error en la petición', error);
      }
    }
  };
  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  };
  return (
    <div>

      <h1>Selecciona una fecha</h1>
      <input
        type="date"
        value={fecha}
        onChange={handleFechaChange}
        max={getTomorrowDate()}
      />
      {/* Si lo deseas, puedes mostrar la fecha seleccionada */}
      {fecha && <p>Fecha seleccionada: {fecha}</p>}
      <>
        <div>
          {ausentes ? <ListeaAusentes datos={ausentes} fecha={fecha} /> : "Sin Ausentes que demostrar Probablemente fue feriado!"}
        </div></>
    </div>
  );
};

export default FechaSelector;


