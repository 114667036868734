import * as React from 'react';
import {useState} from 'react'
import {useNavigate,useParams} from 'react-router-dom';
import Cookies from 'universal-cookie';
import '../cadetes/cosa.css';
import obtieneDatos from './obtieneDatosCookiesUsuario'
import axios from 'axios';
import FooterCorp from '../modulos/footerCorp';
import csvToJson from 'csvtojson'
import { Spinner } from 'reactstrap';
var muestraEdita=false;
const cookies=new Cookies();

function EnvioMasivo(props) {

const navitate = useNavigate()
const Usuario=obtieneDatos()
if(!Usuario){
  alert("Acceso no Autorizado")
   window.location.href='./';
   
}

  const enviaLista=async(listado) => {
    
if(props.Datos!=null){
  setCargando(true)
    const client = axios.create({
    baseURL: 'https://wwwa.isepsantafe.net.ar/expedientes/cadetes/creaMasivo/'
    });
    try {
      const tabla='popos'
      console.log()
    await client.post('/'+props.Datos.api,listado).then(x=> x.data?setCargando(false):"").then(()=>props.myID("dale"))
     
      }
    catch (error) {}}else{alert("Ha Ha Ha");navitate('/usuarioLogueado')}}

  const volver=()=>
  {
    
    navitate('/usuarioLogueado')
  }
  

   
  const [cargando, setCargando] = useState(false);
     const [file, setFile] = useState();
     const [cadetes, setCadetes] = useState();
     const fileReader = new FileReader();
  

     
     const subirArchivoCSV =(e)=>{
try{
  var reader=new FileReader();
  reader.readAsText(e.target.files[0]);
  reader.onload=()=>{

    const simbolo=","
    const simpolo2=";"
   
    csvToJson({
        
      checkType:true,
      delimiter:simpolo2
  })
  .fromString(reader.result)
  .subscribe((jsonObj)=>{
  
  }).then(f=>subirArchivo(f))
//    console.log(event.target.result);

 }
   
  
  reader.onerror=error=>{
      console.log("Error: ",error)

  }
  
}catch{

}
      
    }
   
    const subirArchivo=(f)=>{

      enviaLista(f)

    }

const controloSpinner=()=>{
  if(cargando){
    setCargando(false)
  }else{
    setCargando(true)
  }
}

  return (
<>
    <div className=''>

          <div className=' '>

  
<h1 >Modulo de Upload de archivos CSV</h1>
<br></br>

<form>
{cargando && (<Spinner color='priority'/>)}
{!cargando && <input className='btn btn-success'
    type={"file"}
   name='putito'
    id={"csvFileInput"}
    accept={".csv"}
   onChange={subirArchivoCSV}
  />}
  


</form>

<br />



 <input style={{padding:"15px"}} type="button" value="Cancelar" className="btn btn-secondary" onClick={volver}/>
 </div>
        </div>
       


</>
  );
}
export default EnvioMasivo;
