import { Table } from "reactstrap";
import devuelveDependenciaConstultada from '../tools/devuelveDependencia';
import obtieneDatos from '../tools/obtieneDatosCookiesUsuario';

function DevuelveResoluciones(props) {

const Usuario=obtieneDatos()

const devuelvoCondicion=(objeto)=>{

  
  if(objeto.condicion=="Público"){
    console.log("ES publico")
  const respuesta={"condicion":"Interno","id":objeto.id}  
props.cambioCondicion(respuesta)  
}else{
  console.log("ES Interno")
  const respuesta={"condicion":"Público","id":objeto.id}  
  props.cambioCondicion(respuesta) 
}
}

    const dependencias=devuelveDependenciaConstultada()

  var cantidad2 = 1;

  const usuarioPrivilegios = (
    <>
    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <Table className="table-primary" bordered size="sm">
        <thead>
          <tr style={{ cursor: 'default' }}>
            <th style={{width:"25px"}}>#</th>
            <th style={{width:"135px"}}>Res. Nro</th>
            <th style={{width:"120px"}}>Cond</th>
            <th>Redactó</th>
            <th>Detalle</th>
            <th>NroExp</th>
            <th>Archivo Word</th>
            <th>Resolución Firmada</th>
            
          </tr>
        </thead>
        <tbody  className="table-body">
          {!props.cosas ? "" : props.cosas[0].map((cosas) => (
            <tr style={{ cursor: 'default' }} key={cosas.id} >
              <th  scope="row" style={{width:"25px", cursor: 'default'}} >{cantidad2++}</th>
              <th style={{ cursor: 'default',width:"135px" }}><>{cosas.estado=="PENDIENTE"?<>{cosas.nroResolucion}<button class="btn btn-danger" onClick={()=>props.borraResolucion(cosas.id)} >x</button></>:<>{cosas.nroResolucion}</>}</></th>
              <th  style={{width:"120px", cursor: 'default'}} onClick={()=>devuelvoCondicion(cosas)} >{cosas.condicion=="Público"?<><button class="btn btn-success">{cosas.condicion}</button></>:<><button class="btn btn-danger">{cosas.condicion}</button></>}</th>
              <th style={{ cursor: 'default' }}>{dependencias.find(x=>x.id==cosas.escuelaId).descripcion}</th>
              <th>{cosas.detalle}</th>
              {cosas.expedienteID?<><th><a href={`/consultaExpediente?nroExpediente=${cosas.expedienteID}`} target="_blank" rel="noopener noreferrer">{cosas.expedienteID}</a></th></>:<><th>-</th></>}
              {cosas.archivoWord?<><th><a href={`https://wwwa.isepsantafe.net.ar/resoluciones/${cosas.archivoWord}`} target="_blank" rel="noopener noreferrer">descargar</a>
              {cosas.estado=="EN_PROGRESO"?<button class="btn btn-danger" onClick={()=>props.borrarWord(cosas)}>x</button>:""}</th></>:<th onClick={()=>props.editaWord(cosas)}>Subir Archivo</th>}
            
             
             {cosas.estado=="EN_PROGRESO"?<>{cosas.archivoPdf? 
             <><th><a href={`https://wwwa.isepsantafe.net.ar/resoluciones/${cosas.archivoWord}`} target="_blank" rel="noopener noreferrer">descargar</a><button  class="btn btn-danger"onClick={()=>props.borrarPdf(cosas)}>x</button></th></>:
             <>{Usuario.id=="1"||Usuario.destino=="1"||Usuario.destino=="25"||Usuario.destino=="26"||Usuario.destino=="27"||Usuario.destino=="28"?<th onClick={()=>props.editaPdf(cosas)}>Subir Archivo</th>:<th>-</th>}</>}</>:
             <>{cosas.archivoPdf? <><th><a href={`https://wwwa.isepsantafe.net.ar/resoluciones/${cosas.archivoPdf}`} target="_blank" rel="noopener noreferrer">descargar</a>{Usuario.id=="1"||Usuario.destino=="1"||Usuario.destino=="25"||Usuario.destino=="26"||Usuario.destino=="27"||Usuario.destino=="28"?<button class="btn btn-danger" onClick={()=>props.borrarPdf(cosas)}>x</button>:""}</th></>:<th>-</th>}</>}
              

             
            </tr>
          ))}
        </tbody>
      </Table></div>
    </>
  );

  return (
    <div>
      {usuarioPrivilegios}
    </div>
  );
}

export default DevuelveResoluciones;

