import React, { useRef, useState,useEffect } from 'react';

const CameraComponent = (props) => {
    
    const [isUploading, setIsUploading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [estimatedTime, setEstimatedTime] = useState(null);
    const [enviado, setEnvia] = useState(true);

    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState(null);
  // Iniciar la cámara
  const videoRef = useRef(null);

  const startCamera = async () => {
    try {
      // Obtener todos los dispositivos de video disponibles
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');

      // Buscar la cámara trasera
      const backCamera = videoDevices.find(device => 
        device.label.toLowerCase().includes('back') || 
        device.deviceId.toLowerCase().includes('back')
      );

      const constraints = {
        video: {
          deviceId: backCamera ? { exact: backCamera.deviceId } : undefined
        }
      };

      // Si no encontramos la cámara trasera, usaremos la primera cámara disponible
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks(); // Obtener todas las pistas (video/audio)
      tracks.forEach(track => track.stop()); // Detener cada pista
      videoRef.current.srcObject = null; // Limpiar la referencia al flujo
    }
  };

  useEffect(() => {
    startCamera();
    return () => {
      stopCamera(); // Detener la cámara cuando el componente se desmonta
    };
  }, []);
  // Capturar la imagen
  const captureImage = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
   console.log(props.cadete.cadeteDni + "-" + props.foto+".jpg")
    const newFileName = `${props.cadete.cadeteDni + "-" + props.foto}.jpg`
    canvas.toBlob((blob) => {
           
        const file = new File([blob], `${props.cadete.cadeteDni + "-" + props.foto}.jpg`, { type: 'image/jpeg' });
        setFile(file); // Guardar el archivo para subirlo
        setFileName(newFileName)
        uploadImage(file)
      }, 'image/jpeg');

      
    };
   
    const uploadImage = (file) => {
       
    
        const formData = new FormData();
        formData.append('file', file);
    
        setIsUploading(true);
        setUploadPercentage(0);
    
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://wwwa.isepsantafe.net.ar/expedientes/uploadImagenes', true);
    
        // Manejo del progreso de carga
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentComplete = Math.round((event.loaded * 100) / event.total);
            setUploadPercentage(percentComplete);
    
            // Actualizar el tiempo estimado restante
            const timeElapsed = Math.ceil((event.loaded / 500000)); // Tiempo transcurrido en segundos
            const timeRemaining = estimatedTime - timeElapsed;
            setEstimatedTime(timeRemaining > 0 ? timeRemaining : 0);
          }
        };
    
        xhr.onload = () => {
          setEnvia(false);
          if (xhr.status === 200) {
            alert('Archivo subido correctamente');
            stopCamera()
            setFile(null);
            setUploadPercentage(0);
            setEstimatedTime(null);
            setEnvia(true);
            if (props.onClose) {
              props.onClose(); // Cierra el modal llamando la función pasada como prop
            }
    
            // Pasar el nombre del archivo subido al componente padre
            if (props.onFileUploaded) {
            
              props.onFileUploaded(props.cadete.cadeteDni + "-" + props.foto+".jpg"); // Llamar a la función del componente padre pasando el nombre del archivo
            }

            // Cerrar el modal después de la carga exitosa
            
          } else {
            alert('Error al subir el archivo');
          }
          setIsUploading(false);
        };
    
        xhr.onerror = () => {
          alert('Error de red');
          setIsUploading(false);
        };
    
        xhr.send(formData);
        
      };


  
  return (
    <>

    <div style={{ position: 'relative', width: '330px', height: '100%' }}>
      <video
       id="videoElement"
        ref={videoRef}
        width="100%"
        height="auto"
        autoPlay
        style={{ display: 'block', width: '100%', height: '100%' }}
      />
      
      {isUploading && (
  <div 
    style={{
      position: 'absolute', // Asegura que esté encima de otros elementos
      top: '50%',            // Centrado verticalmente
      left: '50%',           // Centrado horizontalmente
      transform: 'translate(-50%, -50%)', // Compensa el centrado exacto
      width: '100%',         // Asegura que ocupe todo el ancho del contenedor
      textAlign: 'center',   // Alinea el texto al centro
      zIndex: 10,            // Asegura que esté encima de otros elementos
    }}
  >
    <div style={{ width: '100%', backgroundColor: '#f3f3f3' }}>
    <p>subiendo...</p>
      <div
        style={{
          width: `${uploadPercentage}%`,
          height: '20px',
          backgroundColor: '#4caf50',
          transition: 'width 0.5s',
        }}
      />
    </div>
 
  </div>
)}

      
     

      {/* Rectángulo de referencia para el DNI */}

        {/* Texto opcional de referencia */}
        
        
        {props.foto=="foto"?<>
         
        
          <div
  style={{
    position: 'absolute',
    top: '5%', // Posición desde la parte superior de la cámara
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%', // Ajusta el tamaño según la proporción deseada
    height: '90%', // Ajusta la altura según lo necesario
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '4px dashed rgba(26, 226, 0, 0.7)', // Borde punteado
    backgroundColor: 'rgba(2, 53, 2, 0.2)', // Fondo semitransparente
    borderRadius: '20px', // Redondear la parte superior (la cabeza)
 
  }}
>
  {/* Cabeza (óvalo superior) */}
  <div
    style={{
      width: '40%',
      height: '80%',
      borderRadius: '100%', // Forma ovalada
      border: '4px dashed rgba(26, 226, 0, 0.7)', // Borde punteado
      marginBottom: '1%', // Espacio entre la cabeza y los hombros
    }}
  ></div>

  {/* Hombros (rectángulo inferior) */}
  <div
    style={{
      width: '80%',
      height: '40%', // Ajusta la altura de los hombros
      border: '4px dashed rgba(26, 226, 0, 0.7)', // Borde punteado
    }}
  ></div>
</div>

        
        
        
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '22px',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          Imagen del Cadete!!!
        </div></>:<> <div
        style={{
          position: 'absolute',
          top: '20%', // Posición desde la parte superior de la cámara
          left: '50%',
          transform: 'translateX(-50%)',
          width: '75%',
          height: '65%',
          border: '4px dashed rgba(26, 226, 0, 0.7)', // Estilo del borde
          backgroundColor: 'rgba(2, 53, 2, 0.2)', // Fondo semitransparente
        }}
      ></div><div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '22px',
            color: 'white',
            fontWeight: 'bold',
          }}
        >
         {props.foto=="bkDni"?"Reverzo del DNI":"Frente del DNI"}
        </div></>}
         {/* Círculo para capturar la imagen */}
      <div
        onClick={captureImage}
        style={{
          position: 'absolute',
          bottom: 15,
          left: '50%',
          transform: 'translateX(-50%)',
          width: 70,
          height: 70,
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '50%',
          border: 'none',
          cursor: 'pointer',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        {/* Icono dentro del círculo */}
        <span
          style={{
            position: 'absolute',
            top: '45%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '30px',
            color: '#333',
          }}
        >
          📸
        </span>
      </div>
      </div>

     
      
    
   </>
  );
};

export default CameraComponent;
