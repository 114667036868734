import React, { useState, useEffect } from 'react';
import socket from '../tools/socke';
import axios from 'axios';
const Listado = () => {
  const [info, setInfo] = useState(null); // Almacena la información del evento
const traemeLosPresentes=async()=>{
    const client = axios.create({
        baseURL:"https://wwwa.isepsantafe.net.ar/expedientes/devuelvePresentesHoy"
        });
        try {
          await client.post('/').then(f=>console.log(f.data))
          }
        catch (error) {}
}
  useEffect(() => {
    // Manejar el evento 'listaPresentes'
    traemeLosPresentes()
    const handlePresenteCadete = (data) => {
      setInfo(data); // Almacena la información en el estado
    };

    // Escuchar el evento 'listaPresentes' que se emite desde el servidor
    socket.on('listaPresentes', handlePresenteCadete);

    // Limpiar el evento al desmontar el componente
    return () => {
      socket.off('listaPresentes', handlePresenteCadete);
    };
  }, []);

  // Función para ordenar los items por la hora
  const ordenarPorHora = (presentes) => {
    console.log(presentes)
    return presentes
      .sort((a, b) => {
        // Si la hora está en formato 'HH:MM', se puede comparar directamente
        const horaA = a.hora.split(':').map(Number); // [HH, MM]
        const horaB = b.hora.split(':').map(Number); // [HH, MM]
        const minutesA = horaA[0] * 60 + horaA[1]; // Convierte la hora en minutos
        const minutesB = horaB[0] * 60 + horaB[1]; // Convierte la hora en minutos

        return minutesB - minutesA; // Comparar los minutos, de más reciente a más antiguo
      })
      .reverse(); // Revertir el orden para que el más reciente esté al principio
  };

  // Función para determinar el color de fondo según seccionId
  const getColorBySeccion = (companiaId) => {
    if (companiaId === 1) {
      return 'rgb(193, 0, 0)'; // Sección 1 tiene color rojo
    } else if (companiaId === 2) {
      return 'rgb(16, 111, 0)'; // Sección 2 tiene color azul
    } else if (companiaId === 3) {
        return 'rgb(255, 12, 80)'; // Sección 2 tiene color azul 
      } 
    else {
      return 'gray'; // Default a gris si no es ni 1 ni 2
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h1>Presentes de la fecha</h1>
      {info && Array.isArray(info) && info.length > 0 ? (
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', padding: '10px' }}>DNI</th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Compañia</th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Nombre</th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Apellido</th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Tipo</th>
                <th style={{ textAlign: 'left', padding: '10px' }}>Hora</th>
              </tr>
            </thead>
            <tbody>
              {ordenarPorHora(info).map((item) => (
                <tr
                  key={item.dni}
                  style={{
                    backgroundColor: info.indexOf(item) % 2 === 0 ? '#f9f9f9' : '#ffffff',
                    color: '#333',
                  }}
                >
                  <td style={{ padding: '10px', textAlign: 'left' }}>{item.dni}</td>
                  <td
                    style={{
                      padding: '10px',
                      textAlign: 'left',
                      backgroundColor: getColorBySeccion(item.companiaId),
                    }}
                  >
                    {item.companiaId}
                  </td>
                  <td style={{ padding: '10px', textAlign: 'left' }}>{item.nombre}</td>
                  <td style={{ padding: '10px', textAlign: 'left' }}>{item.apellido}</td>
                  <td style={{ padding: '10px', textAlign: 'left' }}>{item.tipo.tipo}</td>
                  <td style={{ padding: '10px', textAlign: 'left' }}>{item.hora || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No se han recibido presentes o no hay datos para mostrar.</p>
      )}
    </div>
  );
  
};

export default Listado;
