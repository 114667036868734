import { useForm } from 'react-hook-form';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import convierteExpediente from  './convierteCadete.js'
import convierteCadeteReincorporar from  './convierteCadeteReincorporar.js'
import { useState } from 'react';
import swal from 'sweetalert'

function FormNuevoExpte (props) {
  const cookies=new Cookies();
  const navitate = useNavigate();
  const ID=cookies.get('id');

  var cierro=false;
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.net.ar/expedientes/'
  
const parametro={}
const usuario=ObtieneDatosCookies()
const datosCurso=props.Datos

  const store=async (data)=>{

   const respuesta=await axios.post(URI+"cadetes/creaCadetePorReincorporacion",convierteCadeteReincorporar(data))
   queHago(respuesta.data).then(cierro?()=>props.myID("dale"):"")
}

  const queHago=async(respuesta)=>{

    if(respuesta.message==="Registro creado correctamente!!"){
      swal("Exito en la reincorporacion de "+respuesta.Respuesta.cadeteApellido )
      cierro=true
    }
    else{
      swal("DNI ya reincorporado consulte con el area Tecnica!!!")
     
    
       }}
   const consultaExistenciaReincorporacion=async (data)=>{

    await axios.post(URI+"cadetes/reincorporaciones/crea",convierteExpediente(data))

   }
  
  const {register, formState: { errors }, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  

const categorias=devuelveCategorias()

  const onSubmit = (data) => {

    store(data)

 
  }

//Aca enpieza el react lo anterior es javascript y hooks


  return (
    <>
    <div >
          
      <form  onSubmit={handleSubmit(onSubmit)}>
       

<div class="row">
<div class='input-group-text' >

          <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" placeholder="Apellido" 
         {...register('cadeteApellido', {
        required: true,
        minLength: 2,
          })}/>
        <label for="floatingInput">Apellido</label>
    </div>
      <div class="form-floating mb-3" >
      <input type="text" class="form-control" id="floatingNombres" placeholder="Nombres" 
      {...register('cadeteNombre', {
        required: true,
        minLength: 3,
      })}/>
      <label for="floatingNombres">Nombres</label>
    </div>
    <div class="form-floating mb-3">
      <input type="number" class="form-control" id="floatingDNI" placeholder="DNI" 
      {...register('cadeteDni', {
        required: true,
        minLength: 8
      })}/>
      <label for="floatingDNI">DNI</label>
    </div>
  
</div>

<div class="row">

          <div class='input-group-text' >
          <div class="form-floating mb-3">
      <input type="number" class="form-control" id="floatingCelular" placeholder="Celular" 
      {...register('cadeteCelular', {

      })}/>
      <label for="floatingCelular">Celular</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" class="form-control" id="floatingDomicilio" placeholder="Domiclio" 
      {...register('cadeteDomicilio', {
       
      })}/>
      <label for="floatingDomicilio">Domicilio</label>
    </div>
    <div class="form-floating mb-3">
  <select class="form-select" id="floatingCadeteGenero" aria-label="CadeteGenero"
  {...register('cadeteGenero', {
    required: true
  })}
  >
   
    <option class="mb-3" value="Marculino">Masculino</option>
    <option class="mb-3" value="Femenino">Femenino</option>
    <option class="mb-3" value="X">X</option>
    
    
  </select>
  <label for="form-floating mb-3">Genero</label>
</div>


    <div class="form-floating mb-3">
  <select class="form-select" id="floatingCadeteSede" aria-label="CadeteSede"
  {...register('cadeteSede', {
    required: true
  })}
  >
   
    <option class="mb-3" value="RECREO">RECREO</option>
    <option class="mb-3" value="MURPHY">MURPHY</option>
    <option class="mb-3" value="RAFAELA">RAFAELA</option>
    <option class="mb-3" value="RECONQUISTA">RECONQUISTA</option>
    <option class="mb-3" value="ROSARIO">ROSARIO</option>
    
  </select>
  <label for="form-floating mb-3">Sede</label>
</div>


</div>
<div class='input-group-text'>
<div class="form-floating mb-3">
  <select class="form-select" id="floatingMotivo" aria-label="Motivo"
  {...register('motivo', {
    required: true,
  })}
  >
   
    <option class="mb-3" value="01 - Nota de Solicitud">Nota de Solicitud</option>
    <option class="mb-3" value="02 - Oficio Judicial">Oficio Judicial</option>
    
  </select>
  <label for="form-floating mb-3">Motivo</label>
</div>
<div class="form-floating mb-3">
  <input value={datosCurso.api} class="form-control" placeholder="CURSO" id="floatingCurso" 
   {...register('promocion', {
    

  })}/>
  <label for="floatingCurso">Curso a ser Reincorporado</label>
</div>
<div class="form-check">
<label class="form-check-label btn-group" for="defaultCheck1">
    Notificado?
  </label>
  <input class="form-check-input" style={{marginLeft:"2px",marginRight:"2px"}}  type="checkbox" value="" id="defaultCheck1" {...register('notificado', {
    

  })}/>
  
</div>

    <div class="form-floating mb-3">
  <textarea class="form-control" placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('descripcion', {
    required: true,
  })}></textarea>
  <label for="floatingDescripcion">Descripcion</label>
</div></div>

<div class='input-group-text'>
<div class="form-floating mb-3">
  <input type='text' class="form-control" placeholder="promocionBaja" id="floatingPbaja" 
   {...register('promocionBaja',  {
    required: true,
  })}/>
  <label for="floatingPbaja">Promocion de Baja</label>
</div>
<div class="form-floating mb-3">
  <input type='text' class="form-control" placeholder="resolucionBaja" id="floatingresolucionBaja" 
   {...register('resolucionBaja',  {
    required: true,
  })}/>
  <label for="floatingresolucionBaja">Resolucion de Baja</label>
</div>
<div class="form-floating mb-3">
  <input type='date' class="form-control" placeholder="fechaBaja" id="floatingfechaBaja" 
   {...register('fechaBaja',  {
    required: true,
  })}/>
  <label for="floatingPbaja">Fecha Baja</label>
</div>
</div>




<div class='input-group-text'>

<div class="form-floating mb-3">
  <input type='text' class="form-control" placeholder="resolucionBaja" id="floatingresolucionAlta" 
   {...register('resolucionAlta',  {
    required: true,
  })}/>
  <label for="floatingresolucionAlta">Res. de Reincorporacion</label>
</div>
<div class="form-floating mb-3" style={{width:"300px"}}>
  <input type='date' class="form-control" placeholder="fechaAlta" id="floatingfechaAlta" 
   {...register('fechaAlta',  {
    required: true,
  })}/>
  <label for="floatingfechaAlta">Fecha de Res. Reincorporacion</label>
</div>
</div>
</div>

<input type="submit" value="Reincorporar"  className="btn btn-success" />

</div>
 </form>

   
        
        </div>
 
        </>
  )}

export default FormNuevoExpte;
