import React, { useState, useEffect } from 'react';
import './Menu.css'; // Asegúrate de incluir este archivo CSS
import {useNavigate,useParams, useSubmit} from 'react-router-dom';
import FooterCorp from '../modulos/footerCorp';
import obtieneDatos from '../tools/obtieneDatosCookiesUsuario';
import CsvDropzone from './CsvDropzone';
import GestorUsuariosCalificaciones from './GestorUsuariosCalificaciones'
import GestorInstancias from './GestorInstancias'
import './CsvDropzone.css'; // Asegúrate de importar el archivo CSS
import Docentes from '../Docentes/Docentes'
import FileUpload from './FileUpload';
import FechaSelector from './fecha';
import socket from '../tools/socke'; 
import Listado from '../Resoluciones/Listado';
import Cadetes from '../cadetes/Cadetes'
import Nuevo from './Nuevo';


const Menu = () => {

const [muestroCsv,setMuestroCsv]=useState(true)
const [listadoPresentes,setListadoPresentes]=useState()
    const Usuario=obtieneDatos();
    
const meRegistro=()=>{
  socket.emit('miCompania',socket.id+" => asignado a  "+localStorage.getItem('nombreCon'))
}
    useEffect(() => {
     
      socket.emit('joinRoom', socket.id);
      socket.on('listaPresentes', (listaObtenida) => {
      
             setListadoPresentes(listaObtenida)
            
              
      });
      socket.on('lectores', (listaObtenida) => {
      
        console.log(listaObtenida)
       
         
 });
      
      return () => {
          socket.off('mensaje'); 
      };
  }, [listadoPresentes]);
    useEffect(() => {
        
      meRegistro()
        if(!Usuario.id){
            alert("Acceso no Autorizado")
             window.location.href='./';
          }
    }, []);
  const navitate = useNavigate()
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
const tomoDatos=(datos)=>{
    
}
const mensaje = () => {
  if (socket) {
    const fechaSolicitada={"fecha":"2024-12-18","nombreCon":localStorage.getItem('nombreCon')}
    socket.emit('creaPresenteCadete',fechaSolicitada); // Reemplaza `userId` con el ID real del usuario
    
  }
  
};

const Component5= () => <div><><Nuevo /></></div>;
const Component4 = () => <div><><FileUpload /></></div>;
  const Component1 = () => <div><><GestorInstancias /></></div>;
  const Component2 = () => <div>{
    
    muestroCsv?<CsvDropzone datos={(value)=>tomoDatos(value)}/>:""}</div>;
  const Component3 = () => <div><><GestorUsuariosCalificaciones /></></div>;
 

 
  const handleMenuClick = (component) => {
    setSelectedComponent(component);
    setIsMenuOpen(false); 
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
const ejecuta=()=>{
 
  socket.emit('disconnectAll',"fechaSolicitada");
}

const apagaLectores=()=>{
 
  socket.emit('apagaLectores',false);
 
}
const que=()=>{
 
  socket.emit('estadoLectores',false);
 
}
const enciendeLectores=()=>
{
  socket.emit('apagaLectores',true);
}
const guarda=()=>{
 
  localStorage.setItem("nombreCon",localStorage.getItem("nombreCon"));
  
 }

 const handleInputChange = (e) => {
  localStorage.setItem("nombreCon",e.target.value);
};
useEffect(() => {
  mensaje()
 
}, []);
  return (
    <div className="menu-container">
      <nav className="menu">
        <div className="hamburger" onClick={toggleMenu}>
          ☰
        </div>
        <div className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
          <div className="menu-item" onClick={() => handleMenuClick('Component1')}>Instancias</div>
          <div className="menu-item" onClick={() => handleMenuClick('Component5')}>Cadetes</div>
          <div className="menu-item" onClick={() => handleMenuClick('Component2')}>Matricular Alumnos</div>
          <div className="menu-item" onClick={() => handleMenuClick('Component3')}>Gestion Usuarios</div>
          <div className="menu-item" onClick={() => handleMenuClick('Component4')}>Prueba</div>
          <div className="menu-item" onClick={() =>  navitate('/usuarioLogueado')}>Volver</div>
        </div>
      </nav>

      <div className="component-display">
        {selectedComponent === 'Component1' && <Component1 />}
        {selectedComponent === 'Component5' && <Component5 />}
        {selectedComponent === 'Component2' && <Component2 />}
        {selectedComponent === 'Component3' && <Component3 />}
        {selectedComponent === 'Component4' && <Component4 />}
        {selectedComponent === 'Component5' && (() =>  navitate('/usuarioLogueado'))}
        <button onClick={()=>mensaje()}>mensaje</button>
      {listadoPresentes?console.log(listadoPresentes):""}
       <button onClick={ejecuta}>ej</button>
       <button onClick={apagaLectores}>apaga</button>
       <button onClick={enciendeLectores}>g</button>
       <button onClick={que}>devuelveEstado</button>
<FechaSelector></FechaSelector>

<Listado></Listado>    <input type="text" name="" id="texto" onChange={handleInputChange}/>
      </div>
    <div class="footer"><FooterCorp /></div>  {()=>mensaje()}
    </div>
  );
};

export default Menu;


