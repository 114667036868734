import { useForm } from 'react-hook-form';
import axios from "axios";
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import convierteExpedienteEditado from  '../adaptadores/convierteExpedienteEditado.js'
import destinodevuelveDependenciaConstultada from '../tools/devuelveDependencia.js'
import swal from 'sweetalert';
import Modal from 'react-modal';
import { useState, useEffect } from 'react';
import ModuloDevuelveCursos from '../cadetes/devuelveCursos.jsx';
import ModuloDevuelveCadetes from './devuelveCadetes.jsx';

function FormEditaExpte (props) {
  const [modalCadetesOpen,setModalCadeteOpen]=useState(false)
  const[esDeCadete,setEsDeCadete]=useState(props.expedienteAEditar?.esDeCadete || "")
  const[veoListados,setVeoListados]=useState(false)
  const[veoListadosCadetes,setVeoListadosCadetes]=useState(false)
  const [cursos,setCursos]=useState()
  const [cadetesCurso,setCadeteCurso]=useState()
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.net.ar/expedientes/'
  
const parametro=props.expedienteAEditar
const usuario=ObtieneDatosCookies()
  

  const actualizaExpediente=async (data)=>{

   await axios.put(URI+"expedientes/actualiza/"+data.id,convierteExpedienteEditado(data)).then(f=>swal({
      title: f.data.message,
      closeOnClickOutside: false,  buttons: {
       
        confirm: "Aceptar"}
    })).then(()=>{props.myID("dale")})
 
   
   }

   const devuelveLosCadetes=()=>{
    setModalCadeteOpen(true)
  }
  const {register, formState: { errors }, watch, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  
const categorias=devuelveCategorias()

  const onSubmit = (data) => {

    actualizaExpediente(data)
 
  }
 
  const fechaMinima = '1995-07-08';
  const fechaMaxima = '2005-07-07';
  const titulo =
    'Generacion de Expedientes ';
  const subTitulo = 'del Instituto de Seguridad Publica';
  
  


//Aca enpieza el react lo anterior es javascript y hooks

const muestroListaCadetes=async(valor)=>{

  const client = axios.create({
    baseURL:process.env.REACT_APP_BASEURL+"cadetes"
    });
    try {
      
     

       const cadetesDevueltos= await client.post("/",{"promocion":valor.api})
        if(cadetesDevueltos){setCadeteCurso(cadetesDevueltos.data.sort(function (a, b) {
          return a.cadeteApellido.localeCompare(b.cadeteApellido);
        }))
      setVeoListadosCadetes(true)
      
      }
    }catch{}



}
useEffect(() => {
  muestraCursos()

}, [modalCadetesOpen]);
const muestraCursos=async()=>{
  setVeoListados(false)
  const client = axios.create({
    baseURL:process.env.REACT_APP_BASEURL+"cadetes/muestraPromociones"
    });
    try {
      const cursosActivos=await client.post('/')
      if(cursosActivos){
        setCursos(cursosActivos.data)
       setVeoListados(true)
      }
      }
    catch (error) {}
}

const tomoCadete=(valor)=>{
  setModalCadeteOpen(false)
  setEsDeCadete(valor.cadeteDni)
}
const customStyles = {
  content: {

  overflowY: "scroll",zIndex:"10"

  },}

  const handleInputChange = (event) => {
    setEsDeCadete(event.target.value);
  };

  return (
    <div>
           <Modal
        isOpen={modalCadetesOpen}
       
        onRequestClose={()=>setModalCadeteOpen(false)}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={()=>setModalCadeteOpen(false)}>x </button> <a style={{alignSelf:"center"}} ><strong>Consulta Listas de Cadetes Actuales: </strong></a>
      


      </div>
      <div>

{veoListados?<><div style={{textAlign:"center"}}><h1>Cursos Activos</h1><ModuloDevuelveCursos cosas={cursos}  myID={(value2)=>muestroListaCadetes(value2)}/></div></>:""}

{
  veoListadosCadetes?<><ModuloDevuelveCadetes cosas={cadetesCurso} myID={(value2)=>tomoCadete(value2)} /></>:""
}




</div>
      </Modal>
  
  <div style={{display:'flex',flexWrap:"wrap",textAlign:"center",gridRowEnd:"-moz-initial",width:"100%",padding:"15px",margin:"10px",backgroundColor:"#005588", color:"#ffeeee"}}>
         
      <h3>Usuario Logueado:{" "+usuario.apellido +", "+usuario.nombre} </h3>
      <h3 style={{marginLeft:"20px"}}>{hoy}</h3>
        <h3 style={{marginLeft:"20px"}}>Division:{destinodevuelveDependenciaConstultada().filter(categoria=>categoria.id==usuario.destino)[0].descripcion} </h3>            </div><div>
          <hr/>
      <form className='form__formulario' onSubmit={handleSubmit(onSubmit)}>
        <section className="datos-personales">
 
         
 

          <div class='input-group-text' >
          <div class="form-floating mb-3 ml-3">
          {errors.iniciador?.type === 'required'&&(<>{alert("Ingrese el nombre del titular")}</>)}
          {errors.iniciador?.type === 'minLength' && (<>{alert("Es muy corto el nombre")}</>)}
          <input  type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
          {...register('iniciador', {
          maxlength:"1024",
          required: true,
          minLength: 3,
          })}/>
          <label for="floatingIniciador">Causante</label>
          </div>
          <div className="form-floating mb-3 ml-3">
      <input
        onClick={devuelveLosCadetes}
        value={esDeCadete}
        onChange={handleInputChange} // This will update the state when the user types
        type="text"
        style={{ minWidth: "200px" }}
        className="form-control"
        id="floatingIniciador"
        placeholder="Es de Cadete?"
        {...register('esDeCadete', {
          // Validation rules can go here
        })}
      />
      <label htmlFor="floatingIniciador">Es de Cadete?</label>
    </div>
          <div class="form-floating mb-3">
          <select class="form-select" id="floatingidCategoria" aria-label="idCategoria"
          {...register('idCategoria', {
           required: true
           })}>
            {categorias.map((cate) =>
            <><option key={cate.id} value={cate.id}>
            {cate.descripcion}
            </option>
            </>
            )}    
    
    
  </select>
  <label for="floatingmotivo">Categoria del Expediente</label>
</div>
<div class="form-floating mb-3 ml-3">
 
          <select class="form-select"value={props.expedienteAEditar.tieneExptAnterior} style={{minWidth:"300px"}} disabled id="tieneExptAnterior" aria-label="tieneExptAnterior"
          {...register('tieneExptAnterior', {
           required: true
           })}>
               <option value="Sistema ISeP">Sistema ISeP</option>
            <option value="Manual">Manual</option>
            <option selected value="NO">NO</option>
    
    
  </select>
  <label for="tieneExptAnterior">Tiene Exp ISeP Anterior</label></div>


  <div class="form-floating mb-3">

          <input  type="text" value={props.expedienteAEditar.idExptAnterior}disabled  style={{minWidth:"320px"}} class="form-control" id="idExptAnterior" placeholder="idExptAnterior" 
          {...register('idExptAnterior', {

          })}/>
          <label for="floatingIniciador">Codigo de Expediente o Nota Precedente</label>
          </div></div>

            
                   
          
          <div>
            
        
          </div>
          <div>
       
          <div class='input-group-text' >
          <div class="form-floating mb-3"style={{width:"120px"}}>
         
         <input type="number" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('fojasIniciales', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Fojas Iniciales</label>
         </div>
          
         <div class="form-floating mb-3"style={{width:"150px"}} >
      <input type="date" class="form-control" id="floatingfechaInicial" placeholder="fechaInicial" 
      {...register('fechaAnterior', {
        minLength: 3,
      })}/>
      <label for="floatingfechaMinisterio">Fecha Anterior?</label>
    </div>
          <div class="form-floating mb-3 ml-3">
         
          <input type="text" class="form-control" style={{minWidth:"250px"}} id="floatingIniciador" placeholder="iniciador" 
          {...register('idExpMinisterio', {
         
            pattern:/(?:\d{5}|\(\d{3}\))([-\/\.])\d{7}\1\d{1}/
          })}/>
          <label for="floatingIniciador">Codigo de Expediente Ministerio</label>
          </div>
          
          
          
          
          
          
          <div class="form-floating mb-3" >
      <input type="date" class="form-control" style={{minWidth:"300px"}} id="floatingfechaMinisterio" placeholder="fechaMinisterio" 
      {...register('fechaMinisterio', {
        minLength: 3,
      })}/>
      <label for="floatingfechaMinisterio">Fecha de Exp. Inicial Ministerio</label>
    </div>  {errors.idExpMinisterio?.type === 'pattern' && (
            <span className="aviso_formulario">Ojo, el formato debe ser 12345-1234567-1</span>)}
          </div>
            
        
          <div class="form-floating">
  <textarea class="form-control" style={{height:"100px",resize:"none"}} placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('concepto', {
    required: true,
    maxLength:1000 
  })}></textarea>
  <label for="floatingDescripcion">Concepto</label>
</div>    
            
          </div>
                    <div className="contenedor-boton" >

        
<input type="submit" value="Finalizar"  style={{padding:"10px"}}className="btn btn-success" />
<input type="button" value="Cancelar"  className="btn btn-warning" onClick={()=>{props.myID("dale")}} />

</div>
   
        </section>

        </form></div>
     
    </div>
  );
};
export default FormEditaExpte;
