import React, { useState, useEffect } from "react";
import axios from 'axios';
import regresaBienFecha from "../tools/regresaBienFecha";
import Swal from "sweetalert2";
const AdministraFeriados = () => {
  const [feriados, SetFeriados] = useState(null);

  const [fecha, setFecha] = useState('');
  const [motivo, setMotivo] = useState('');
const[veoCreoFeriado,setVeoCreoFeriado]=useState(false)
  const devuelveFeriados = async () => {
    const client = axios.create({
      baseURL: "https://wwwa.isepsantafe.net.ar/expedientes/devuelveFeriados"
    });
    try {
      const response = await client.post('/');
      console.log(response.data);
      SetFeriados(response.data); // Guardar los datos en el estado
    } catch (error) {
      console.error("Error al obtener los feriados:", error);
    }
  };

  // Este useEffect se ejecuta solo cuando el componente se monta
  useEffect(() => {
    devuelveFeriados();
  }, []); // El array vacío [] asegura que se ejecute solo una vez al montarse el componente
  
  
  
  const construyeFeriado=()=>{
    setVeoCreoFeriado(!veoCreoFeriado)

  }
  const creaFeriado = async () => {

   
    const client = axios.create({
      baseURL: "https://wwwa.isepsantafe.net.ar/expedientes/creaFeriado"
    });
    try {
   
      const datos={
        "fecha":fecha,
        "descripcion":motivo
      }
      console.log(datos)
      const response = await client.post('/',datos);
     
      if(response.message=="Validation error"){
        console.log(response.data);
        alert("ya existe ese feriado!!!!");
        devuelveFeriados(); // Guardar los datos en el estado
      }else{
     
        devuelveFeriados()
      }
    
    } catch (error) {
      console.error("Error al obtener los feriados:", error);
    }
  };
  const handleCreaFeriado = () => {
    // Lógica para procesar los datos
    if (fecha && motivo) {
creaFeriado()
      
    } else {
      alert("Por favor, complete todos los campos.");
    }
  };


  return (
    < ><div style={{border:"1px grey solid"}}>
      <h2>Administra Feriados</h2>
<button type="button" onClick={()=>construyeFeriado()}>Crea Feriado</button>

      {veoCreoFeriado ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center", // Para centrar contenido verticalmente
              height: "100px",
           width:"100%",
            
            }}
          >
            {/* Aquí puedes agregar contenido como texto o componentes dentro del div */}
            <form>
      <input
        type="date"
        value={fecha} // Vinculamos el estado de la fecha al valor del input
        onChange={(e) => setFecha(e.target.value)} // Actualizamos el estado cuando cambia el valor
      />
      <input
        type="text"
        placeholder="Motivo"
        value={motivo} // Vinculamos el estado del motivo al valor del input
        onChange={(e) => setMotivo(e.target.value)} // Actualizamos el estado cuando cambia el valor
      />
      <input
        type="button"
        value="Crear"
        onClick={handleCreaFeriado} // Ejecutamos la función cuando se hace clic en el botón
      />
    </form>
          </div>
        </>
      ) : (
       ""
      )}
      {feriados ? (
     
          feriados.feriado.map((dia, index) => (
            <li key={index}>{regresaBienFecha(dia.fecha)} Motivo: {dia.descripcion}</li> // Devolver el li correctamente
          ))
   
      ) : (
        <p>Cargando feriados...</p>
      )}
  </div>  </>
  );
};

export default AdministraFeriados;



