import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import regresaBienFecha from '../tools/regresaBienFecha.js';
//*import './GestorUsuariosCalificaciones.css'
import Modal from 'react-modal';
import Check from './Check.jsx'
import './creaInstancia.css'
const CreaInstancia = (props) => {
  const InstanciasActivas=props.Instancias
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { register, handleSubmit, reset } = useForm();
  const [modalAbierto,setModalAbierto]=useState(false)
  const[cursosDisponibles,setCursosDisponibles]=useState(null)


const customStyles = {
    content: {
        
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
  
    },
  };

const devuelveInstancias=async(estado)=>{
  if(!estado){
    const resultado= await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/calificaciones/devuelveInstancias/')
if(resultado){

setUsers(resultado.data.instanciaDevuelta)
}

  }else{
    const resultado= await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/calificaciones/devuelveInstancias?estado='+estado)
    if(resultado){
     
      setUsers(resultado.data.instanciaDevuelta)
    }
  }
 
}

  useEffect(() => {
    // Fetch users from the API
    axios.post('https://wwwa.isepsantafe.net.ar/expedientes/cursos/devuelveCursosPorEscuela/Todo',{"estadoCurso":"Activo"})
      .then(response => {

        setCursosDisponibles(response.data.Cursos.sort((a, b) =>{ a.nombreCurso.localeCompare(b.nombreCurso)}))
      
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleEditClick = (user) => {
    setModalAbierto(true)
    setSelectedUser(user);
    reset(user); // Populate the form with the selected user's data
  };
  const handleSelectChange = (event) => {
    setSelectedCurso(event.target.value);
    setSelectedNombreCurso(cursosDisponibles.find(id=>id.id==event.target.value).nombreCurso);
   
  };
  const onSubmit = (data) => {
    axios.put(`/api/users/${data.dni}`, data)
      .then(response => {
        // Update the local state
        setUsers(users.map(user => user.id === data.id ? data : user));
        setSelectedUser(null);
      })
      .catch(error => {
        console.error('Error updating user:', error);
      });
  };
const closeModalInformeDocentes=()=>{
    setModalAbierto(false)
}
const [selectedCurso, setSelectedCurso] = useState("");
  const [nombreInstancia, setNombreInstancia] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [nombreCurso, setSelectedNombreCurso] = useState("");
  const [estado, setEstado] = useState(true);

 

  const handleNombreInstanciaChange = (e) => {
    setNombreInstancia(e.target.value);

  };

  const handleFechaInicioChange = (e) => {
    setFechaInicio(e.target.value);
  };
  const handleFechaFinalChange = (e) => {
    setFechaFinal(e.target.value);
  };
  const handleEstadoChange = (e) => {
    setEstado(e.target.checked);
  };

  const handleCrear =async () => {
    const datos=
    {
      "cursoId":selectedCurso,
nombreCurso,
      nombreInstancia,
      fechaInicio,
      fechaFinal,
      "estado":"Activo",
    };
   const crearInstancia=await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/calificaciones/creaInstancia',datos)
    if(crearInstancia){
      console.log(crearInstancia.data)
      handleBorrarTodo()
    }

  };

  const handleBorrarTodo = () => {
    setSelectedCurso("");
    setNombreInstancia("");
    setFechaInicio("");
    setFechaFinal("");
    setEstado(true);
    props.myID("dale")
  };
  const isFormValid = selectedCurso && nombreInstancia && fechaInicio && fechaFinal;

  const options = ['Primer Parcial', 'Segundo Parcial', 'Final', 'Recuperatorio'];
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value.toLowerCase();
    setNombreInstancia(value);

    if (value) {
      const filtered = options.filter(option =>
        option.toLowerCase().includes(value)
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions([]);
    }
  };

  const handleOptionClick = (option) => {
    setNombreInstancia(option);
    setFilteredOptions([]);
  };




  return (
    <>
   <div>
   
    {cursosDisponibles?<>
     
      <form className="form-container" action="" method="post">
      <div className="form-group">
        <label htmlFor="select">Seleccione un curso:</label>
        <select id="select" value={selectedCurso} onChange={handleSelectChange}>
          <option value="" disabled>
            -- Selecciona un curso --
          </option>
          {cursosDisponibles.map((cate) => (
            <option key={cate.id} value={cate.id}>
              {cate.nombreCurso}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="nombreInstancia">Nombre de Instancia:</label>
        <input
          type="text"
          id="nombreInstancia"
          value={nombreInstancia}
          onChange={handleInputChange}
          placeholder="Nombre de la Instancia"
        />
      </div>
      <div style={{ position: 'relative', maxWidth: '300px' }}>

      {filteredOptions.length > 0 && (
        <div className="suggestions" style={suggestionsStyle}>
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              onClick={() => handleOptionClick(option)}
              style={suggestionItemStyle}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
      <div className="form-group">
        <label htmlFor="fechaInicio">Fecha de Inicio de Instancia:</label>
        <input
          type="date"
          id="fechaInicio"
          value={fechaInicio}
          onChange={handleFechaInicioChange}
         
        />
      </div>
      <div className="form-group">
        <label htmlFor="fechaInicio">Fecha Final de Instancia:</label>
        <input
          type="date"
          id="fechaFinal"
          value={fechaFinal}
          onChange={handleFechaFinalChange}
         
        />
      </div>
     

      <div className="form-buttons">
        <button type="button" onClick={handleCrear} disabled={!isFormValid}>
          Crear
        </button>
        <button type="button" onClick={handleBorrarTodo}>
          Borrar Todo
        </button>
      </div>
    </form>
    </>:"Cargando"}
    </div></>
  );
};
const suggestionsStyle = {
  border: '1px solid #ccc',
  backgroundColor: 'white',
  maxHeight: '150px',
  overflowY: 'auto',
  position: 'absolute',
  width: '100%',
  zIndex: 1000,
};

const suggestionItemStyle = {
  padding: '8px',
  cursor: 'pointer',
};
export default CreaInstancia;
