import React, { useState, useEffect } from "react";
import axios from "axios";
import ListeaCadetes from "./ListeaCadetes";
import socket from "../tools/socke";

const ReempadronaCadete = () => {
  const [listadoPromocion, setListadoPromocion] = useState([]);
  const [visible, setVisible] = useState(false);
  const [listaObtenida, setListaObtenida] = useState(null);
  const [filtro, setFiltro] = useState("");
  const [cadetesFiltrados, setCadetesFiltrados] = useState([]);
  const [promocionSeleccionada, setPromocionSeleccionada] = useState("");

  // Escuchar el evento del WebSocket
  useEffect(() => {
    socket.on("estadoActualizable", () => {
      if (filtro) {
        obtieneCadete(filtro); // Usar el filtro actual
      }
    });

    return () => {
      socket.off("estadoActualizable");
    };
  }, [filtro]); // Depender del filtro actual

  // Función para obtener los cadetes filtrados desde el backend
  const obtieneCadete = async (dato) => {
    try {
      if (dato.length > 1) {
        const username = "nocapinyass"; // Reemplaza con tu nombre de usuario
        const password = "yourPassword"; // Reemplaza con tu contraseña
        const token = btoa(`${username}:${password}`);

        const response = await axios.post(
          `https://wwwa.isepsantafe.net.ar/expedientes/NCadetes/devuelveCadete/${dato}`,
          { estado: "activo" },
          {
            headers: {
              Authorization: `Basic ${token}`,
            },
          }
        );

        setVisible(true);
        console.log(response.data.datos);
        setCadetesFiltrados(response.data.datos || []);
      } else {
        setCadetesFiltrados([]);
      }
    } catch (error) {
      console.error("Error en la petición", error);
    }
  };

  // Manejar cambios en el filtro
  const handleInputChange = (e) => {
    const texto = e.target.value;
    setFiltro(texto);
    obtieneCadete(filtro)
  };

  // Emitir evento con el WebSocket
  const enciendeLectores = (va) => {
    obtieneCadete(filtro); // Usar el filtro actual
    setPromocionSeleccionada(va.Promocions.nombre);
    socket.emit("reempadrone", va.Promocions.nombre);
  };

  return (
    <div>
      <div>Servicio de Reempadronamiento ISeP USUARIO:{}</div>
      <div>
        <input
          type="text"
          placeholder="Buscar por apellido o DNI"
          value={filtro}
          onChange={handleInputChange}
          style={{
            padding: "10px",
            fontSize: "16px",
            marginBottom: "10px",
            width: "50%",
            boxSizing: "border-box",
          }}
        />
      </div>
      {visible && cadetesFiltrados && cadetesFiltrados.length > 0 && (
        <>
          <ListeaCadetes
            actualiza={(value) => enciendeLectores(value)}
            items={cadetesFiltrados}
          />
        </>
      )}
    </div>
  );
};

export default ReempadronaCadete;
