import { useForm } from 'react-hook-form';
import axios from "axios";
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import { useState } from 'react';
import swal from 'sweetalert'



function FormEditaCurso (props) {

  const cookies=new Cookies();
  const fecha = new Date();
  const hoy = "Fecha: "+fecha.toLocaleDateString();
  const parametros=props.Datos
  const usuario=ObtieneDatosCookies()
  const guardarCurso=async (data)=>{
  const URI='https://wwwa.isepsantafe.net.ar/expedientes/'
  data.usuarioID=usuario.id;
  data.sedeID=usuario.sedeID;
  data.dependenciaID=usuario.destino
  const respuesta= await axios.post(URI+"cursos/creaCurso/",data).then(()=>props.cas(data.escuela))
  alert("Correcto!")  
}






  const {register, formState: { errors }, handleSubmit,} = useForm({
  defaultValues: parametros//recupero lo que pasa por parametros
  });
  const onSubmit = (data) => {
  guardarCurso(data)
  }
  return (
    <>
    <div >
    <form id="asd" onSubmit={handleSubmit(onSubmit)}>
    <div class="form-floating mb-3">
      <input type="text" value={props.Datos  }class="form-control" id="floatingDomicilio" placeholder="fecha Finalizacion" 
      {...register('escuela',{
        required: true,
      })}/>
      <label for="floatingDomicilio">Escuela</label>
    </div>
    <div class='input-group-text' >
    <div class="form-floating mb-3">
    <input type="text" class="form-control" id="floatingInput" placeholder="Nombre Materia" 
         {...register('nombreCurso', {
        required: true,
        minLength: 3,
          })}/>
        <label for="floatingInput">Nombre del Curso</label>
    </div>
    <div class="form-floating mb-3" >
    <input type="number" class="form-control" id="floatingNombres" placeholder="Cantidad" 
      {...register('cantDivisiones', {
        required: true,
        minLength: 1,
      })}/>
      <label for="floatingNombres">Cantidad de divisiones</label>
    </div>

     
</div>
<div class="row">
 <div class='input-group-text' >
 <div class="form-floating mb-3">
       <select class="form-select" id="floatingestadoMateria" aria-label="estadoMateria"
  {...register('estadoCurso', {
    required: true
  })}
 >
   
    <option class="mb-3" selected value="Activo">Activo</option>
    <option class="mb-3" value="Inactivo">Inactivo</option>
   </select>
  <label for="form-floating mb-3">Estado</label>
</div>

          <div class="form-floating mb-3">
      <input type="date" class="form-control" id="floatingCelular" placeholder="fecha Inicio" 
      {...register('fechaInicio', {
        required: true,
      })}/>
      <label for="floatingTelefono">Fecha de Inicio</label>
    </div>
    <div class="form-floating mb-3">
      <input type="date" class="form-control" id="floatingDomicilio" placeholder="fecha Finalizacion" 
      {...register('fechaFinalizacion', {
        required: true,
      })}/>
      <label for="floatingDomicilio">Fecha de Finalizacion</label>
    </div>
  
    <div class="form-floating mb-3" style={{paddingLeft:"10px"}}>
<input type="submit" value="Crear Curso"  className="btn btn-success" />
<input type="button" value="Cancelar" onClick={(a)=>props.cas(a)} className="btn btn-danger" />

</div>
    </div>
   

</div>
 </form>

   
        
 </div>       
 
      
 </>)
 
 }

export default FormEditaCurso;
