import { useForm } from 'react-hook-form';
import axios from "axios";
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import { useState } from 'react';
import swal from 'sweetalert'



function FormEditaMateria (props) {

  const cookies=new Cookies();
  
  const Cursos=props.Cursos.Cursos



  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

 
const parametros=props.Datos

const usuario=ObtieneDatosCookies()


  const guardarMateria=async (data)=>{

    const URI='https://wwwa.isepsantafe.net.ar/expedientes/'
    
    data.usuarioID=usuario.id;
    data.sedeID=usuario.sedeID;
    data.dependenciaID=usuario.destino

  const respuesta= await axios.post(URI+"materias/creaMateria/",data).then(()=>props.cas(data.escuela))
  alert("Correcto!")
  
  
}

  
  const {register, formState: { errors },watch, handleSubmit,} = useForm({
  defaultValues: parametros//recupero lo que pasa por parametros
  });
  


  const onSubmit = (data) => {

const cursito=Cursos.filter(x=>x.id==cursoSeleccionado)[0]

data.cantDivisiones=cursito.cantDivisiones
data.curso=cursito.nombreCurso
data.fechaInicio=cursito.fechaInicio
data.fechaFinalizacion=cursito.fechaFinalizacion

 guardarMateria(data)
  }
  
var cursoSeleccionado=watch('curso',Cursos.filter(x=>x!=null)[0].id)

  return (
    <>
    <div >
  
      <form id="asd" onSubmit={handleSubmit(onSubmit)}>
       


<div class='input-group-text' >

          <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" placeholder="Nombre Materia" 
         {...register('nombreMateria', {
        required: true,
        minLength: 3,
          })}/>
        <label for="floatingInput">Nombre de la Materia</label>
    </div>
   
     
    <div class="form-floating mb-3" style={{width:"100px"}}>
      <input type="number" class="form-control" id="floatingDNI" placeholder="Horas" 
      {...register('bloqueHorario', {
        required: true,
        minLength: 1
      })}/>
      <label for="floatingDNI">Horas</label>
    </div>
    <div class="form-floating mb-3">
      
<select class="form-select" id="floatingestadoMateria"   aria-label="estadoMateria" 
 {...register('curso', {
  required: true,
})}
 >
            {Cursos.map((cate) =>
            <option key={cate.id} value={cate.id}>
            {cate.nombreCurso}
            
 </option>)}
   </select>
      <label for="floatingDNI">Curso</label>
    </div>
     <div class="form-floating mb-3">
       <select class="form-select" id="floatingestadoMateria" aria-label="estadoMateria"
  {...register('estadoMateria', {
    required: true
  })}
  >
   
    
    <option class="mb-3" selected value="Activo">Activo</option>
    <option class="mb-3" value="Inactivo">Inactivo</option>
    
    
  </select>
  <label for="form-floating mb-3">Estado</label>
</div>
</div>

<div class="row">

          <div class='input-group-text' >
         


        

   
<div>

</div>
</div>


<div class='input-group-text' style={{alignContent:"center",alignSelf:"center",gridRowStart:"auto"}}>
<div class="form-floating mb-3">
      <input type="text" value={props.Datos  }class="form-control" id="floatingDomicilio" placeholder="fecha Finalizacion" 
      {...register('escuela',{
        required: true,
      })}/>
      <label for="floatingDomicilio">Escuela</label>
    </div>
<input type="submit" value="Crear Materia"  className="btn btn-success" />
<input type="button" value="Cancelar" onClick={(a)=>props.cas(a)} className="btn btn-danger" />


 


</div>

</div>
 </form>

   
        
 </div>       
 
      
 </>)
 
 }

export default FormEditaMateria;
