import React , { useState, useEffect }from "react";
import {useNavigate} from 'react-router-dom';
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
import DevuelveProcesos from './devuelveProcesos'
import DevuelveEtapas from './devuelveEtapas'
import { Spinner } from 'reactstrap';
import axios from 'axios';
import Ingresos from './Ingresos'
import convierteDataProvincia from './convierteDataProvincia'
import CargaMasiva from "./CargaMasiva";
import CargaDNI from "./CargaDNI";
import Modal from 'react-modal';
function Procesos () {
  const Usuario=obtieneDatos();
  if(!Usuario.id){
    alert("Acceso no Autorizado")
     window.location.href='./';
  }
  const customStyles = {
    content: {
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
 
    },
  };




    const navitate = useNavigate()
   
    const[muestroIngreso,setMuestroIngreso]=useState(false) 
    const[procesos,setProcesos]=useState(false)
    const[etapas,setEtapas]=useState(false)
    const[muestroProcesos,setMuestroProcesos]=useState(false)
    const[muestroEtapas,setMuestroEtapas]=useState(false)
    const[etapaSeleccionada,setEtapaSeleccionada]=useState(false)
    const[muestroPostulantes,setMuestroPostulantes]=useState(false)
    const [cargando, setCargando] = useState(false);
    const[procesoSeleccionado,setProcesoSeleccionado]=useState({}) 
    const [muestroCargaMasiva,setMuestroCargaMasiva]=useState(false)
const muestrasProcesos=async()=>{
  setMuestroProcesos(false)
  const client = axios.create({
      baseURL:process.env.REACT_APP_BASEURL+"procesos/muestraProcesos"
      });
      try {
        await client.post('/').then(f=>setProcesos(f.data)).then(setMuestroProcesos(true))
        }
      catch (error) {}
 
}
const muestrasEtapas=async(valor)=>{
  setMuestroEtapas(false)
  const objetpo=valor
  setProcesoSeleccionado(false)
  setMuestroIngreso(false)
  
  const client = axios.create({
      baseURL:process.env.REACT_APP_BASEURL+"procesos/muestraEtapas"
      });
      try {
       const cosa= await client.post('/'+valor.id)
if(cosa){

setEtapas(cosa.data)
setMuestroEtapas(true)
setCargando(false)

seteaProcesoActual(objetpo)
setMuestroIngreso(true)

}
       
        }
      catch (error) {}
 
}
const seteaProcesoActual=async(objeto)=>{
 
  setProcesoSeleccionado(objeto)
  
}
const crearNuevoProceso=async(promocion)=>{
  const client = axios.create({
      baseURL:process.env.REACT_APP_BASEURL+"procesos/crearProceso"
      });
      try {
        await client.post('/',promocion).then((alert("Proceso "+promocion.nombre  +" creado correctamente"))).then(setMuestroProcesos(true)).then(actualizaCursos)
       
        }
      catch (error) {}
 
}
const enviaCorreMasivo=async(etapa)=>{

 let mensajesEnviados=[]
  const cliente = axios.create({
    baseURL:process.env.REACT_APP_BASEURL+"procesos/devuelvePostulanteEtapa/"

    });
    try {
 const postulantesEtapa=await cliente.post('/'+etapaSeleccionada.apiEtapa)
 if(postulantesEtapa){


  const postulantes=postulantesEtapa.data
  console.log(postulantes)
  const client = axios.create({
    baseURL:"https://isepsantafe.net.ar/co",
    headers: {
      'Content-Type': 'text/html', // Especificar el tipo de contenido como HTML
      'Accept': 'text/html',       // Opcional: indicar que aceptas respuestas en HTML
  }
  
    });
    for (let index = 69; index < postulantes.length; index++) {//
    const mensajeSegundaEtapa=`<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Instituto de Seguridad Pública</title>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap" rel="stylesheet">
    <style>
        body {
            font-family: 'Roboto', sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }
        .form-register {
            max-width: 600px;
            margin: 20px auto;
            background-color: #ffffff;
            border-radius: 15px;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
        }
        .form-register h3, .form-register h3 {
            text-align: center;
            color: #333;
            margin: 0;
        }
        .form-register h3 {
            margin-bottom: 5px;
        }
        .form-register h3 img {
            display: block;
            margin: 0 auto;
            padding: 10px;
        }
        .form-register p, .form-register ul, .form-register li {
            font-size: 16px;
            line-height: 1.5;
            color: #333;
        }
        .form-register ul {
            padding-left: 20px;
        }
        .form-register a {
            color: #007bff;
            text-decoration: none;
        }
        .form-register a:hover {
            text-decoration: underline;
        }
        .button-link {
            display: inline-block;
            margin-top: 10px;
            padding: 10px 20px;
            background-color: #007bff;
            color: #fff;
            border-radius: 5px;
            text-align: center;
            text-decoration: none;
        }
        .button-link:hover {
            background-color: #0056b3;
        }
        .footer {
            font-size: 8px;
            text-align: center;
            color: #999;
            margin-top: 20px;
        }
    </style>
</head>
<body>
  <p style="text-align: justify;">&nbsp;</p>
<section class="form-register">
<h3 style="text-align: center;"><img style="display: block; margin-left: auto; margin-right: auto;" src="https://www.isepsantafe.net.ar/images/Escudos%20Isep/escudo_Isep_sin_fondopeque.png" alt="escudoIsep" width="85" height="79" /> Instituto de Seguridad P&uacute;blica</h3>
<h3 style="text-align: center;">Provincia de Santa Fe</h3>
<hr />
<h1 style="text-align: center;">CONVOCATORIA A LA SEGUNDA ETAPA DEL PROCESO DE SELECCI&Oacute;N CICLO 2025-2026</h1>
<hr />
<p>Estimado <strong>${postulantes[index].apellidoInscripto}, ${postulantes[index].nombresInscripto}</strong>:</p>
<p style="text-align: justify;">EL INSTITUTO DE SEGURIDAD P&Uacute;BLICA PONE EN CONOCIMIENTO A UD QUE DEBER&Aacute; PRESENTARSE A REALIZAR LA SEGUNDA ETAPA DEL PROCESO DE SELECCI&Oacute;N PARA EL CICLO 2025-2026 (CARPETA MEDICA, EXAMEN DE APTITUD FISICA Y EXAMEN INTELECTUAL)</p>
<p><strong>SEDE</strong>: <a href="${postulantes[index].ubicacionMaps}" target="_blank" rel="noopener">${postulantes[index].sedeConvocado}</a></p>
<p><strong>FECHA</strong>: ${postulantes[index].fechaConvocado}</p>
<p><strong>HORARIO</strong>: ${postulantes[index].horaConvocado}</p>
<p>TENGA EN CUENTA QUE:&nbsp;</p>
<p style="text-align: justify;">DEBER&Aacute; PRESENTARSE SEG&Uacute;N SEDE Y HORARIO ASIGNADO PARA REALIZAR EXAMEN F&Iacute;SICO, EXAMEN INTELECTUAL Y ENTREGA DE ESTUDIOS COMPLEMENTARIOS (CARPETA M&Eacute;DICA)&nbsp;</p>
<p style="text-align: justify;">PARA CONOCIMIENTO DE QUIENES CONTINUAN, EL MANUAL DE ESTUDIO PARA RENDIR EL EXAMEN INTELECTUAL SE ENCUENTRA PUBLICADO EN LA SECCION BIBLIOTECA DE LA PAGINA WEB DEL I.SE.P. <a href="https://www.isepsantafe.net.ar/index.php/academico/biblioteca/biblioteca" target="_blank" rel="noopener">EN EL SIGUIENTE ENLACE</a>&nbsp;DE LA MISMA MANERA SE ENCUENTRAN PUBLICADAS LAS <a href="https://www.isepsantafe.net.ar/index.php/academico/biblioteca/biblioteca" target="_blank" rel="noopener">PRUEBAS F&Iacute;SICAS</a></p>
<p style="text-align: center;"><span style="color: #ff0000;"><strong><u>MUY IMPORTANTE:</u></strong><strong> RECUERDEN QUE DEBEN ASISTIR CON LA CREDENCIAL DE POSTULANTE A FIN DE QUE SE LES PUEDA DAR EL PRESENTE.</strong></span></p>
<p style="text-align: center;"><span style="color: #800000;"><strong><u>MUY IMPORTANTE:</u></strong><strong>&nbsp;SE DEBER&Aacute; PRESENTAR UN APTO F&Iacute;SICO, JUNTO CON UN ELECTROCARDIOGRAMA INFORMADO POR M&Eacute;DICO CARDI&Oacute;LOGO&nbsp;(NO DEBE SER POR M&Eacute;DICO CL&Iacute;NICO)&nbsp;Y ADEM&Aacute;S DEBE CONTENER DE MANERA TEXTUAL LA&nbsp;FRASE <u>&ldquo;APTO PARA REALIZAR ACTIVIDAD F&Iacute;SICA&rdquo;</u>.&nbsp;LA NO ESPECIFICACI&Oacute;N DE ESTA LEYENDA EN EL APTO DEPORTIVO SER&Aacute; CAUSAL DE NO PODER REALIZAR EL EXAMEN DE APTITUD F&Iacute;SICA Y POR ENDE, LOS DEM&Aacute;S EXAMENES.</strong></span></p>
<p style="text-align: justify;"><strong><em><u>VESTIMENTA:</u></em></strong><strong><em>&nbsp;</em></strong><strong>DEBER&Aacute;N PRESENTARSE VESTIDOS DE GIMNASIA (NO SHORTS, NO CALZA) SE SUGIERE TRAER AGUA PARA HIDRATARSE Y UNA BARRITA DE CEREAL, FRUTA O COLACI&Oacute;N PARA MITAD DE MA&Ntilde;ANA.</strong></p>
<p style="text-align: justify;"><span style="text-decoration: underline;">PARA ESTA ETAPA DEBER&Aacute; PRESENTAR LOS SIGUIENTES ESTUDIOS MEDICOS:</span></p>
<ol>
<ul>
<li>RADIOGRAFIA DE TORAX &ndash; (Frente) INFORMADA</li>
<li>RADIOGRAFIA DE COLUMNA LUMBOSACRA</li>
<li>Frente INFORMADA</li>
<li>Perfil INFORMADA</li>
<li>Ambas Oblicuas a 45&ordm; INFORMADA&nbsp;&nbsp;</li>
</ul>
</ol>
<p style="text-align: center;">(EN CASO DE SER PRESENTADOS LOS ESTUDIOS EN CD,&nbsp;<strong>ES NECESARIO Y OBLIGATORIO PRESENTAR INFORME IMPRESO</strong>)</p>
<ol>
<ul>
<li>CERTIFICADOBUCODENTAL &nbsp;</li>
<li>EXAMEN OFTALMOLOGICO: informado por Oftalm&oacute;logo.</li>
</ul>
<ul>
<li>Agudeza Visual Ambos Ojos S/Correcci&oacute;n y C/Correcci&oacute;n.&nbsp; &nbsp;</li>
<li>Motilidad Ocular.&nbsp; &nbsp;</li>
<li>Visi&oacute;n Crom&aacute;tica.&nbsp; &nbsp;</li>
<li>Presi&oacute;n Endo-ocular.&nbsp;&nbsp;</li>
</ul>
<ul>
<li>AUDIOMETRIA TONAL CON ACUFENOMETRIA. &nbsp; &nbsp;&nbsp;</li>
<li>COPIA FOTOSTATICA DE CONSTANCIAS DE VACUNACION</li>
<li>ACTUALIZADA (se debe solicitar lo expuesto en un vacunatorio)&nbsp; &nbsp;</li>
<li>APTO CARDIOVASCULAR CON ECG INFORMADO POR CARDI&Oacute;LOGO</li>
<li>ANALISIS DE LABORATORIO</li>
</ul>
<ul>
<li>&nbsp;</li>
</ul>
<ul>
<li>HEMOGRAMA COMPLETO&nbsp;</li>
<li>GRUPO SANGUINEO Y FACTOR RH.&nbsp; &nbsp;</li>
<li>GLUCEMIA&nbsp; &nbsp;</li>
<li>CREATININA&nbsp; &nbsp;</li>
<li>COLESTEROLEMIA&nbsp; &nbsp;</li>
<li>URICEMIA&nbsp; &nbsp;</li>
<li>V.D.R.L.&nbsp; &nbsp;</li>
<li>ORINA COMPLETA&nbsp; &nbsp;&nbsp;</li>
</ul>
</ol>
<p>&nbsp;</p>
<p style="text-align: center;"><span style="color: #ff0000;">&nbsp; &nbsp;&nbsp;<strong>LA DOCUMENTACI&Oacute;N M&Eacute;DICA DEBERA SER ENTREGADA EN UN SOBRE PAPEL MADERA DE 37X45cm.&nbsp;</strong></span></p>
<h6 style="text-align: justify;">Reglamento de alumnado I.Se.P: Secci&oacute;n III PROCESO DE SELECCI&Oacute;N, art. 22 &hellip; &ldquo;Los postulantes que no se presenten en las fechas y horarios establecidos para los distintos ex&aacute;menes o lo hagan sin su DNI, ser&aacute;n separados del proceso de selecci&oacute;n, sin derecho a reclamo&rdquo;&hellip;</h6>
<p>Saludos cordiales.</p>
<p>ISeP</p>
<hr />
<p class="footer"><span style="text-decoration: underline;"><span style="color: #ff0000;"><strong>No conteste este correo, ya que el mismo se genera de forma autom&aacute;tica.</strong></span></span></p>
</section>
</body>
</html>`
      const mensaje=`
<!DOCTYPE html>
<html lang="es">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Instituto de Seguridad Pública</title>
    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap" rel="stylesheet">
    <style>
        body {
            font-family: 'Roboto', sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }
        .form-register {
            max-width: 600px;
            margin: 20px auto;
            background-color: #ffffff;
            border-radius: 15px;
            padding: 20px;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
        }
        .form-register h3, .form-register h3 {
            text-align: center;
            color: #333;
            margin: 0;
        }
        .form-register h3 {
            margin-bottom: 5px;
        }
        .form-register h3 img {
            display: block;
            margin: 0 auto;
            padding: 10px;
        }
        .form-register p, .form-register ul, .form-register li {
            font-size: 16px;
            line-height: 1.5;
            color: #333;
        }
        .form-register ul {
            padding-left: 20px;
        }
        .form-register a {
            color: #007bff;
            text-decoration: none;
        }
        .form-register a:hover {
            text-decoration: underline;
        }
        .button-link {
            display: inline-block;
            margin-top: 10px;
            padding: 10px 20px;
            background-color: #007bff;
            color: #fff;
            border-radius: 5px;
            text-align: center;
            text-decoration: none;
        }
        .button-link:hover {
            background-color: #0056b3;
        }
        .footer {
            font-size: 8px;
            text-align: center;
            color: #999;
            margin-top: 20px;
        }
    </style>
</head>
<body>
  <p style="text-align: justify;">&nbsp;</p>
  <section class="form-register">
  <h3><img src="https://www.isepsantafe.net.ar/images/Escudos%20Isep/escudo_Isep_sin_fondopeque.png" alt="escudoIsep" width="85" height="79" /> Instituto de Seguridad P&uacute;blica</h3>
  <h3>Provincia de Santa Fe</h3>
  <hr />
  <h1 style="text-align: center;">CONVOCATORIA A LA PRIMERA ETAPA DEL PROCESO DE SELECCI&Oacute;N CICLO 2025-2026</h1>
  <hr />
  <p>Estimado <strong>${postulantes[index].apellidoInscripto}, ${postulantes[index].nombresInscripto}</strong>:</p>
  <p style="text-align: justify;">EL INSTITUTO DE SEGURIDAD P&Uacute;BLICA PONE EN CONOCIMIENTO A UD QUE DEBER&Aacute; PRESENTARSE A REALIZAR LA PRIMERA ETAPA DEL PROCESO DE SELECCI&Oacute;N PARA EL CICLO 2025-2026 (EXAMEN PSICOL&Oacute;GICO Y PRIMER EXAMEN M&Eacute;DICO)</p>
  <p><strong>SEDE</strong>: <a href="${postulantes[index].ubicacionMaps}" target="_blank" rel="noopener">${postulantes[index].sedeConvocado}</a></p>
  <p><strong>FECHA</strong>: ${postulantes[index].fechaConvocado}</p>
  <p><strong>HORARIO</strong>: ${postulantes[index].horaConvocado}</p>
  <p><span style="text-decoration: underline;">RECUERDE QUE PARA LA PRIMERA ETAPA DEBE CONTAR CON LA SIGUIENTE DOCUMENTACI&Oacute;N:</span></p>
  <ol>
  <li><a href="https://certificados.isepsantafe.net.ar/imprimeCredencial?dni=${postulantes[index].dniInscripto}">Credencial de Proceso de Selecci&oacute;n (click aqu&iacute; para descargar)</a></li>
  <li>Fotocopia del DNI <strong>(legalizada)</strong></li>
  <li>Fotocopia de Partida de Nacimiento <strong>(legalizada)</strong></li>
  <li>1 (una) foto tipo carnet 4x4, fondo celeste, perfil derecho.</li>
  <li>Constancia de finalizaci&oacute;n de estudios secundarios registrada en el <a href="https://www.santafe.gov.ar/index.php/tramites/modul1/index?m=descripcion&amp;id=118174" target="_blank" rel="noopener">Ministerio de Educaci&oacute;n</a> o constancia de alumno regular <strong>(para quienes est&eacute;n en el &uacute;ltimo a&ntilde;o). </strong>En este punto se aclara que al momento de incorporaci&oacute;n (Marzo 2025) <strong>&iexcl;NO SE DEBEN ADEUDAR MATERIAS!</strong></li>
  <li>Constancia de domicilio (solicitar la misma en distritos correspondientes o juzgados de paz)</li>
  <li>Legajo de inscripci&oacute;n (Extendido al momento de su inscripci&oacute;n en la pagina santafe.gob.ar)</li>
  </ol>
  <p><span style="text-decoration: underline;">PARA EL EXAMEN PSICOL&Oacute;GICO</span>:</p>
  <ul>
  <li>1 (un) l&aacute;piz negro N&deg; 2HB</li>
  <li>1 (una) goma de borrar</li>
  <li>1 (una) birome Azul</li>
  </ul>
  <p>Ubicaci&oacute;n de la sede asignada <a href="${postulantes[index].ubicacionMaps}" target="_blank" rel="noopener">(Consulte Aqui)<img src="https://i.pinimg.com/originals/66/1e/98/661e98a8e38f681575da93d0a1c3f4fc.png" alt="Ubicaci&oacute;n" width="31" height="31" /></a></p>
  <p style="text-align: justify;"><span style="color: #ff0000;">SE HACE SABER QUE TODA LA DOCUMENTACI&Oacute;N SE DEBE PRESENTAR EN FOLIO OFICIO. SE DEBE PRESENTAR LA TOTALIDAD DE LA DOCUMENTACI&Oacute;N SIN EXCEPCI&Oacute;N. DEBER&Aacute;N VENIR VESTIDOS DE GIMNASIA (NO CALZA, NO SHORTS) MUJERES CON PELO RECOGIDO.</span></p>
  <h6>Reglamento de alumnado I.Se.P: Secci&oacute;n III PROCESO DE SELECCI&Oacute;N, art. 22 &hellip; &ldquo;Los postulantes que no se presenten en las fechas y horarios establecidos para los distintos ex&aacute;menes o lo hagan sin su DNI, ser&aacute;n separados del proceso de selecci&oacute;n, sin derecho a reclamo&rdquo;&hellip;</h6>
  <p>Saludos cordiales.</p>
  <p>ISeP</p>
  <hr />
  <p class="footer"><strong>No conteste este correo, ya que el mismo se genera de forma autom&aacute;tica.</strong></p>
  </section>
</body>
</html>
`
const payload = {
html: mensajeSegundaEtapa
};
//postulantes[index].emailInscripto
     const mensajeEnviado= await client.post('/'+"?mail="+postulantes[index].emailInscripto+"&subject="+postulantes[index].nombresInscripto + " "+ postulantes[index].apellidoInscripto+"&nombre="+postulantes[index].nombresInscripto+"&dni="+postulantes[index].dniInscripto+"&fecha="+postulantes[index].fechaConvocado,mensajeSegundaEtapa)
     
     console.log("mensaje ORDEN nro "+index+ " Enviado a "+postulantes[index].dniInscripto + " correo: "+postulantes[index].emailInscripto) 
    
     if(mensajeEnviado){
      console.log(mensajeEnviado)
     mensajesEnviados.push(mensajeEnviado.data)
      }

    }console.log(mensajesEnviados)
 }
    }catch{}
  

       


      try {
        
       
       
        }
      catch (error) {}
 
}

const crearNuevEtapa=async(proceso)=>{
  const client = axios.create({
      baseURL:process.env.REACT_APP_BASEURL+"procesos/crearEtapaProceso"
      });
      try {
        await client.post('/',proceso).then((alert("Etapa "+proceso.nombreEtapa  +" creado correctamente"))).then(setMuestroProcesos(true)).then(actualizaCursos)
       
        }
      catch (error) {}
 
}
const actualizaCursos=()=>{
  setProcesos(false)
  setMuestroProcesos(false)
  setMuestroProcesos(true)
  
}
const headers = [
  { label: "Nro Control", key: "id" },
  { label: "DNI", key: "cadeteDni" },
  { label: "Nombres", key: "cadeteNombre" },
  { label: "Apellido", key: "cadeteApellido" },
  { label: "Sede", key: "cadeteSede" },
  { label: "Estado", key: "cadeteEstado" },
 
];




const volver=()=>{
    navitate('/usuarioLogueado')
}
if(!muestroProcesos){
  muestrasProcesos()

}


const crearProceso=()=>{
  const valor = window.prompt("Ingrese el nombre del proceso!")
  
  if(!valor){

  }else{
    const ultimaLetra=valor.substring(valor.length - 1)
  
    if(ultimaLetra!='s'){
      const Objeto={
        
        nombre:"proceso_"+valor,
        proceso:valor,
        escuela:"Escuela de Policia",
        api:"proceso_"+valor+"s",
        etapa:"inscripcion",
        estado:"Activo",
   
      }
        crearNuevoProceso(Objeto)
    }
    
  else{alert("no puede terminar en la letra s")}
  }
  

}

const crearEtapaProceso=()=>{
  const valor = window.prompt("Ingrese el nombre de la Etapa!")
  if(!valor){

  }else{
    const ultimaLetra=valor.substring(valor.length - 1)
  
    if(ultimaLetra!='s'){
      const Objeto={
        
        nombreEtapa:valor,
        nombre:"etapa_"+valor+"_Proceso_"+procesoSeleccionado.proceso,
        idProceso:procesoSeleccionado.id,
        escuela:"Escuela de Policia",
        apiEtapa:"etapa_"+valor+"_Proceso_"+procesoSeleccionado.proceso+"s",
        estadoEtapa:"Activo",
  
      
      
      }
        crearNuevEtapa(Objeto)
    }
    
  else{alert("no puede terminar en la letra s")}
    }
  }

const buscaEtapas=(objeto)=>{

  setCargando(true)
  setProcesoSeleccionado(objeto)
  setEtapaSeleccionada(null)
  muestrasEtapas(objeto)

}

const modificoEstado=async(grupo)=>{
try {
var resultado=[]
const hora=document.getElementById("hora").value
const sede=document.getElementById("sede").value
const fecha=document.getElementById("fecha").value
const ubicacionMaps=document.getElementById("ubicacionMaps").value

setCargando(true)
  const client = axios.create({
    baseURL:process.env.REACT_APP_BASEURL+"procesos/actualizoPostulante/"
    });


     const mando= await axios.post('https://wwwa.isepsantafe.net.ar/expedientes/procesos/creaPresente/'+etapaSeleccionada.apiEtapa,{"grupo":grupo,"proceso":procesoSeleccionado.api, "horaConvocado":hora,"fechaConvocado":fecha,"sedeConvocado":sede,"ubicacionMaps":ubicacionMaps})
if(mando){
  
if(mando.data=="No se agrego nada"){
  alert(mando.data)
}else{
  alert("Se agregaron "+alert(mando.data.length)+" postulantes nuevos a la etapa seleccionada")
}
  
  setCargando(false)
  
}
} catch (error) {
  setCargando(false)
}

}
const actualizoEstado=async(infor)=>
{
  const clientec = axios.create({
    baseURL:process.env.REACT_APP_BASEURL+"procesos/modificaEstadoMasivo"
    });

try{console.log(etapaSeleccionada)
  const envio= await axios.post(process.env.REACT_APP_BASEURL+"procesos/modificaEstadoMasivo"+"?estado="+etapaSeleccionada.nombreEtapa,{proceso:"proceso_2025-2026s",grupo:infor})
  
  if(envio){
    console.log(envio)
  }
}catch{

}
   
}
const seteoEtapa=(objeto)=>{

  setEtapaSeleccionada(objeto)
  
}

    return(
        <>
         <div >
         <Modal
        isOpen={cargando}
       
       
        style={customStyles}
        ariaHideApp={false}
      >Cargando...<Spinner>

</Spinner>
      </Modal>
      </div>
        <div style={{textAlign:"center", backgroundColor:"#afaacc", margin:"5px",minWidth:"358px"}}>
           <h1 >Menu de Gestion de Procesos de ingreso de Postulantes</h1> 
           <h3>Usuario logueado:{Usuario.nombre}</h3>
           <div className="btn-group" >
           <button className="btn btn-warning" onClick={volver}>Volver</button>
           {Usuario.destino=="7"?<button className="btn btn-primary" onClick={crearProceso}>Crear Proceso</button>:""}
           </div>
           
           </div>
           <div style={{ color:"white",textAlign:"center",minWidth:"358px", maxWidth:"400",minHeight:"600",backgroundColor:"#aaa", margin:"5px"}}>
<h1 >Procesos Activos</h1>

{muestroProcesos?(<>
<div style={{backgroundColor:"grey",minWidth:"358px"}}>
{console.log(procesos)}
{procesos.length>0?<DevuelveProcesos cosas={procesos} myID={(value)=>buscaEtapas(value)} />:"Sin procesos encontrados"}


</div>
{muestroProcesos&&muestroEtapas?(
  Usuario.destino==="7"?(<>
 

  {muestroCargaMasiva?<CargaMasiva datosObtenidos={(datos)=>convierteDataProvincia(datos)} myID={()=>setMuestroCargaMasiva(false)}/>:""}
  <button className="btn btn-danger" onClick={crearEtapaProceso}>Crear Etapa</button></>):""):""}
</>):""}
 
{muestroEtapas?(<>
<div style={{backgroundColor:"grey",minWidth:"358px"}}>
<DevuelveEtapas cosas={etapas} myID={(value)=>seteoEtapa(value)} />


</div>

</>):""}
{etapaSeleccionada&&muestroEtapas?<h1>{"Etapa Seleccionada: "+etapaSeleccionada.nombreEtapa}</h1>:""}
{muestroEtapas?()=>{setMuestroPostulantes(!muestroPostulantes)}:""}
{etapaSeleccionada&&muestroEtapas&&Usuario.destino=="7"? 
<> 
<button onClick={()=>enviaCorreMasivo()}>Envia</button>
<div>
<CargaDNI datos={(info)=>modificoEstado(info)}/> 
<input
          placeholder="Sede"
            id="sede"
        />
        <input
          placeholder="Ubicacion"
         id="ubicacionMaps"
        />
          <input
          placeholder="Fecha"
         id="fecha"
        />
          <input
          placeholder="Hora"
             id="hora"
        /></div>
        <div>
    Actualizo Estados
 <CargaDNI datos={(info)=>actualizoEstado(info)}/> 
  </div>
  </>
  :""
  }
 
{etapaSeleccionada&&muestroEtapas?<>

 
    
<Ingresos datos={procesoSeleccionado} etapa={etapaSeleccionada}/></>:""}

           </div>
           
          
        </>
    )
}
export default Procesos