import React from "react";
import AdministraFeriados from "./AdministraFeriados";

const Configuraciones = () => {return(

    <>Configuraciones
    <div><AdministraFeriados/></div>
    
    </>
)}

export default Configuraciones