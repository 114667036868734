import Child from "../tools/Child";

const Nuevo = () => {
    
   
  
    return (
      <div >
        <div >
          <Child direccion={(value) => alert(value)}></Child>
          <button >
           
          </button>
        
        </div>
      </div>
    );
  };
  
  export default Nuevo;