import { useForm } from 'react-hook-form';
import axios from "axios";
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveDependenciaConstultada from '../tools/devuelveDependencia.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import Imagen64 from '../tools/imagenABase64.js'
import { useState } from 'react';

function FormEditoCertificado2 (props) {
  const cookies=new Cookies();
  const ID=cookies.get('id');

  const [disableBotonFinalizar, setDisableBotonFinalizar]=useState(false)
  const [firma1, setFirma1]=useState(props.cursoEditar.firma1?props.cursoEditar.firma1:"")
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.net.ar/expedientes/'
  
const parametro=props.cursoEditar
const usuario=ObtieneDatosCookies()
  

  

  const {unregister,register, formState: { errors }, watch, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  
const categorias=devuelveCategorias()

  const onSubmit = (data) => {
  data.firma1=firma1
  modificoCurso(data)
    }

  
 
    const modificoCurso=async(proceso)=>{
  
      const client = axios.create({
          baseURL:process.env.REACT_APP_BASEURL+"certificados/editaCertificadoCurso/"
          });
          try {
            proceso.estado="activo"
            
          
           const creando= await client.put('/'+ proceso.id,proceso)
           if(creando){
           alert("Curso "+proceso.nombreCurso+" editado correctamente")
           props.myID("dale")
           }
           
            }
          catch (error) {}
     
    }


  

  return (

     
  <>
  <div style={{display:'flex',flexWrap:"wrap",textAlign:"center",gridRowEnd:"-moz-initial",width:"100%",  padding:"15px",margin:"10px",backgroundColor:"yellow", color:"black"}}>
         
      <h3>Usuario Logueado:{" "+usuario.apellido +", "+usuario.nombre} </h3>
      <h3 style={{marginLeft:"20px"}}>{hoy}</h3>
        <h3 style={{marginLeft:"20px"}}>Division:{devuelveDependenciaConstultada().filter(categoria=>categoria.id==usuario.destino)[0].descripcion} </h3>            </div>
        
        
        <div>






      <form className='form__formulario' onSubmit={handleSubmit(onSubmit)}>
        <section className="datos-personales">

<div id='escudos'>
  
<div >
  <hr/>
  <h4>Datos Generales de la certificación</h4>

<div class='input-group-text'>
<div class="form-floating mb-3 ml-3">
          <input  type="text" style={{minWidth:"350px"}} class="form-control" id="tituloIniciador" placeholder="tituloIniciador" 
          {...register('nombreCurso', {
          maxlength:"1024",
          required: true,
          minLength: 3,
          })}/>
          <label for="tituloIniciador">Nombre interno</label></div>
        
<div class="form-floating mb-3 ml-3">

<input  type="text" style={{minWidth:"200px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('reginterno', {
maxlength:"1024",
required: true,
minLength: 6,
})}/>
   <label for="floatingTitulo">Registro Interno</label>

</div>

<div class="form-floating mb-3 ml-3">
<input  type="text" style={{minWidth:"200px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('organizadoPor', {
maxlength:"1024",
required: true,
minLength: 3,
})}/>
   <label for="floatingTitulo">Organizador</label>
   </div>
  

          </div> 
          <div class='input-group-text'>
          <div class="form-floating mb-3 ml-3">
<input  type="text" style={{width:"180px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('cantidadHoras', {
maxlength:"1024",
required: true,
minLength: 1,
})}/>
   <label for="floatingTitulo">Duracion</label>
   </div>
   <div class="form-floating mb-3 ml-3">
<select  style={{width:"180px"}}  class="form-select" id="floatingTipo" placeholder="floatingTipo" 
{...register('tipoCapacitacion', {
maxlength:"1024",
required: true,
minLength: 1,
})}>
 <option selected value="Curso">Curso</option>
 <option value="Capacitación">Capacitación</option>
 <option value="Taller">Taller</option>
 <option value="Jornada">Jornada</option>
 <option value="Seminario">Seminario</option>
  </select>
   <label for="floatingTipo">Tipo</label>
   </div>

<div class="form-floating mb-3 ml-4">

<input  type="text" style={{minWidth:"250px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('resolucionCertificado', {
maxlength:"1024",
required: true,
minLength: 1,
})}/>
   <label for="floatingTitulo">Resolución Direccion General</label>

</div>
<div class="form-floating mb-3 ml-3">

<input  type="text" style={{minWidth:"200px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('resolucionJPP', {
maxlength:"1024",
required: true,
minLength: 1,
})}/>
   <label for="floatingTitulo">Resolución JPP</label>

</div>
  </div> 
          <div class='input-group-text'>
                 
          <div class="form-floating mb-3 ml-3">

<input  type="text" style={{minWidth:"350px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('tituloCertificado', {
maxlength:"1024",
required: true,
minLength: 3,
})}/>
   <label for="floatingTitulo">Titulo Obtenido</label>

</div>

          <div class="form-floating mb-3"style={{width:"180px"}} >
      <input  type="text"id="fechaCursado" class="form-control" placeholder="fechaCursado" 
      {...register('fechaRealizado', {
        required:true
       })}/>
      <label for="fechaCursado">Fecha de realizacion</label>
    </div>
    <div class="form-floating mb-3 ml-3">
<select  style={{width:"180px"}}  class="form-select" id="floatingTipo" placeholder="floatingTipo" 
{...register('claseCertificado', {
maxlength:"1024",
required: true,
minLength: 1,
})}>
 <option selected value="Clase1">Clase1</option>
 
 
  </select>
   <label for="floatingTipo">Clase Tipo</label>
   </div>
   <div class="form-floating mb-3 ml-3">
<select  style={{width:"180px"}}  class="form-select" id="floatingTipo" placeholder="floatingTipo" 
{...register('ciudadCertificado', {
maxlength:"1024",
required: true,
minLength: 1,
})}>
 <option selected value="Recreo">Recreo</option>
 <option value="Rosario">Rosario</option>
 
  </select>
   <label for="floatingTipo">Ciudad Certificado</label>
   </div>
    </div>
</div></div> 
 
          <div >
            <hr/>
            <h4>Firma principal</h4>

          <div class='input-group-text' >
          
          <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text"value={"Lic Goyenechea Mario"} class="form-control" id="floatingIniciador" placeholder="firmante1" 
         {...register('firmante1', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Firmante Principal</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" value={"Director de Policia"} class="form-control" id="floatingIniciador" placeholder="firmanteJer" 
         {...register('firmante1jer', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Jerarquia Fir Ppal</label>
         </div>
         <div class="form-floating mb-3"style={{width:"250px", marginRight:"10px"}}>
         
         <input type="text"  value={"Director General del ISeP"}class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante1cargo', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Cargo Fir Ppal</label>
         </div>
        
</div>


          <div>
          
          </div>
            
          </div>
                    <div className="contenedor-boton" >

        
<input type="submit" disabled={disableBotonFinalizar} value="Finalizar"  className="btn btn-success" />

<input type="button" value="Cancelar"  className="btn btn-warning" onClick={()=>{props.myID("dale")}} />



</div>
   
        </section>

        </form>
        
        </div>
     </>

  );
};
export default FormEditoCertificado2;
