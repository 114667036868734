import React, { useState } from "react";
import Swal from "sweetalert2";

const CadeteForm = ({ cadete, onClose }) => {
  const [formData, setFormData] = useState({ ...cadete });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const muestroFoto = (valor) => {
    window.open('https://wwwa.isepsantafe.net.ar/imagenes/' + valor)
  }
  const handleReempadronar = () => {
    const { cadeteCiudad, cadeteCria, cadeteCelular, cadeteUR, cadeteCuil, cadeteEmail, cadeteGenero, cadeteGrupoSanguineo } = formData;
    const formControls = document.querySelectorAll(".form-control");
    const hasInvalidFields = Array.from(formControls).some((input) =>
      input.classList.contains("is-invalid")
    );
  
    if (hasInvalidFields) {
      Swal.fire({
        title: "Error",
        text: "Por favor, corrige los campos en rojo.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }
    if (!cadeteCiudad || !cadeteCria || !cadeteCelular || !cadeteUR || !cadeteCuil || !cadeteEmail || !cadeteGenero || !cadeteGrupoSanguineo) {
      Swal.fire({
        title: "Error",
        text: "Por favor, completa todos los campos requeridos.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    Swal.fire({
      title: "¿Estás seguro?",
      text: "Vas a reempadronar al cadete.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, reempadronar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Reempadronado", "El cadete ha sido reempadronado.", "success");
        if (onClose) onClose(formData);
      }
    });
  };

  const handleCancel = () => {
    Swal.fire({
      title: "¿Cancelar cambios?",
      text: "Se perderán todos los cambios no guardados.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, cancelar",
      cancelButtonText: "Continuar editando",
    }).then((result) => {
      if (result.isConfirmed) {
        if (onClose) onClose();
      }
    });
  };
  const today = new Date();
  
  // Calcular la fecha mínima: 18 años atrás
  const minDate = new Date(today);
  minDate.setFullYear(today.getFullYear() - 18);

  // Formatear la fecha a 'YYYY-MM-DD' para el valor del input
  const minDateString = minDate.toISOString().split('T')[0];
  return (
    <div style={{ height: "500px", width: "90%", maxWidth: "650px", minWidth: "300px", overflow: "auto", margin: "0 auto", }}
    >

      <div>

        <h3 className="text-center">Formulario de Reempadronamiento de Cadetes</h3>
        <div className="d-flex justify-content-center gap-3 mb-3">
          <img
            src={`https://wwwa.isepsantafe.net.ar/imagenes/${formData.fotoCadete}`}
            alt="Foto Cadete"
            className="rounded-circle border"
            style={{ height: "75px", width: "75px", cursor: "pointer" }}
            onClick={() => muestroFoto(formData.fotoCadete)}
          />
          <img
            src={`https://wwwa.isepsantafe.net.ar/imagenes/${formData.fotoFrenteDni}`}
            alt="Foto Frente DNI"
            className="border"
            style={{ height: "120px", width: "150px", objectFit: "cover" }}
            onClick={() => muestroFoto(formData.fotoFrenteDni)}
          />
          <img
            src={`https://wwwa.isepsantafe.net.ar/imagenes/${formData.fotoReversoDni}`}
            alt="Foto Reverso DNI"
            className="border"
            style={{ height: "120px", width: "150px", objectFit: "cover" }}
            onClick={() => muestroFoto(formData.fotoReversoDni)}
          />
        </div>
      </div>
      <form style={{ textAlign: "center" }}>
        <div className="input-group-text">
          <div className="">
            <label>DNI</label>
            <input
              type="number"
              name="cadeteDni"
              value={formData.cadeteDni || ""}
              className="form-control"
              readOnly
            />
          </div>
          <div className="">
            <label>Apellido Completo</label>
            <input
              type="text"
              name="cadeteApellido"
              value={formData.cadeteApellido || ""}
              onChange={handleInputChange}
              required
              className={`form-control ${!formData.cadeteApellido ? "is-invalid" : "is-valid"
                }`}
            />
          </div>
          <div className="">
            <label>Nombre Completo</label>
            <input
              type="text"
              name="cadeteNombre"
              value={formData.cadeteNombre || ""}
              onChange={handleInputChange}
              required
              className={`form-control ${!formData.cadeteNombre ? "is-invalid" : "is-valid"
                }`}
            />
          </div>
        </div>
        <div className="input-group-text">
          <div id="CUIL" className="">
            <label>CUIL</label>
            <input
              type="text"
              name="cadeteCuil"
              value={formData.cadeteCuil || ""}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, ""); // Remueve todo lo que no sea un dígito
                handleInputChange({
                  target: {
                    name: e.target.name,
                    value: numericValue,
                  },
                });
              }}
              onBlur={(e) => {
                const isValidLength = e.target.value.length === 11; // Valida longitud de 10
                if (!isValidLength) {
                  handleInputChange({
                    target: {
                      name: e.target.name,
                      value: "", // Limpia el valor si no es válido
                    },
                  });
                }
              }}
              required
              maxLength={11}
              className={`form-control ${formData.cadeteCuil?.length === 11 ? "is-valid" : "is-invalid"
                }`}
            />
          </div>
          <div id="Grupo Sanguineo" className="">
            <label>Grupo Sanguineo</label>
            <select
              name="cadeteGrupoSanguineo"
              value={formData.cadeteGrupoSanguineo || ""}
              onChange={handleInputChange}
              required
              className={`form-select ${!formData.cadeteGrupoSanguineo ? "is-invalid" : "is-valid"
                }`}
            >
              <option value="">Seleccionar...</option>

              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>

            </select>
          </div>

          <div className="">
            <label>Ciudad</label>
            <input
              type="text"
              name="cadeteCiudad"
              value={formData.cadeteCiudad || ""}
              onChange={handleInputChange}
              required
              className={`form-control ${!formData.cadeteCiudad ? "is-invalid" : "is-valid"
                }`}
            />
          </div>
          <div className="">
            <label>Domicilio</label>
            <input
              type="text"
              name="cadeteDomicilio"
              value={formData.cadeteDomicilio || ""}
              onChange={handleInputChange}
              required
              className={`form-control ${!formData.cadeteCiudad ? "is-invalid" : "is-valid"
                }`}
            />
          </div>
        </div>
        <div className="input-group-text">
          <div id="Departamento" className="">
            <label>Departamento</label>
            <select
              name="cadeteUR"
              value={formData.cadeteUR || ""}
              onChange={handleInputChange}
              required
              className={`form-select ${!formData.cadeteUR ? "is-invalid" : "is-valid"
                }`}
            >
              <option value="">Seleccionar...</option>

              <option value="La Capital">La Capital</option>
              <option value="Rosario">Rosario</option>
              <option value="Belgrano">Belgrano</option>
              <option value="Caseros">Caseros</option>
              <option value="Castellanos">Castellanos</option>
              <option value="Constitución">Constitución</option>
              <option value="Garay">Garay</option>
              <option value="General López">General López</option>
              <option value="General Obligado">General Obligado</option>
              <option value="Iriondo">Iriondo</option>
              <option value="Las Colonias">Las Colonias</option>
              <option value="Nueve de Julio">Nueve de Julio</option>
              <option value="San Cristóbal">San Cristóbal</option>
              <option value="San Javier">San Javier</option>
              <option value="San Jerónimo">San Jerónimo</option>
              <option value="San Justo">San Justo</option>
              <option value="San Lorenzo">San Lorenzo</option>
              <option value="San Martín">San Martín</option>
              <option value="Vera">Vera</option>
            </select>
          </div>
          <div id="Comisaría" style={{ width: "120px" }} className="">
            <label>Comisaría</label>
            <input
              type="text"
              name="cadeteCria"
              value={formData.cadeteCria || ""}
              onChange={handleInputChange}
              required
              className={`form-control ${!formData.cadeteCria ? "is-invalid" : "is-valid"
                }`}
            />
            
          </div>
          <div id="Sede" className="">
            <label>Sede</label>
            <select
              name="cadeteSede"
              value={formData.cadeteSede || ""}
              onChange={handleInputChange}
              required
              className={`form-select ${!formData.cadeteSede ? "is-invalid" : "is-valid"
                }`}
            >
              <option value="">Seleccionar...</option>

              <option value="Recreo">Recreo</option>
              <option value="Rosario">Rosario</option>
              


            </select>
          </div>
            <div id="Género" className="">
            <label>Género</label>
            <select
              name="cadeteGenero"
              value={formData.cadeteGenero || ""}
              onChange={handleInputChange}
              required
              className={`form-select ${!formData.cadeteGenero ? "is-invalid" : "is-valid"
                }`}
            >
              <option value="">Seleccionar...</option>

              <option value="Masculino">Masculino</option>
              <option value="Femenino">Femenino</option>
              <option value="X">X</option>


            </select>
          </div>

        </div>
        <div className="input-group-text">
        <div id="Nacimiento" className="">
        <label>Nacimiento</label>
        <input
          type="date"
          name="fechaNacimiento"
          value={formData.fechaNacimiento || ""}
          onChange={handleInputChange}
          required
          max={minDateString} // Fecha mínima (18 años atrás)
          className={`form-control ${!formData.fechaNacimiento ? "is-invalid" : "is-valid"}`}
        />
      </div>
          <div id="Email" className="">
            <label>Email</label>
            <input
              type="email"
              name="cadeteEmail"
              value={formData.cadeteEmail || ""}
              onChange={handleInputChange}
              required
              className={`form-control ${/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.cadeteEmail || "")
                  ? "is-valid"
                  : "is-invalid"
                }`}
            />

          </div>
          <div id="Celular" className="">
            <label>Celular</label>
            <input
              type="text"
              name="cadeteCelular"
              value={formData.cadeteCelular || ""}
              onChange={handleInputChange}
              required
              className={`form-control ${!formData.cadeteCelular ? "is-invalid" : "is-valid"
                }`}
            />
          </div>





        </div>
        <div className="">
          <button
            type="button"
            className="btn btn-primary me-2"
            onClick={handleReempadronar}
          >
            {formData.cadeteCondicion != "Convocado" ? "Guardar Cambios" : "Reempadronar"}
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleCancel}
          >
            Cancelar
          </button>
        </div>
      </form>

    </div>
  );
};

export default CadeteForm;
