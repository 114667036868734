import React, { useState, useEffect } from 'react';
import './Menu.css'; // Asegúrate de incluir este archivo CSS
import { useNavigate, useParams, useSubmit } from 'react-router-dom';
import FooterCorp from '../modulos/footerCorp';
import obtieneDatos from '../tools/obtieneDatosCookiesUsuario';
import './CsvDropzone.css'; // Asegúrate de importar el archivo CSS
import socket from '../tools/socke';
import ObtienePromociones from './ObtienePromociones';
import ReempadronaCadete from './ReempadronaCadete';
import FechaSelector from '../Calificaciones/fecha';
import Listado from './Listado';
import Configuraciones from './Configuraciones'

const Menu = () => {

  const [listadoPresentes, setListadoPresentes] = useState()
  const Usuario = obtieneDatos();


  useEffect(() => {

  
    socket.on('listaPresentes', (listaObtenida) => {

      setListadoPresentes(listaObtenida)


    });
    
    if (!Usuario.id) {
      alert("Acceso no Autorizado")
      window.location.href = './';
    }
  }, [listadoPresentes]);

  const navitate = useNavigate()
  const [selectedComponent, setSelectedComponent] = useState('Component2');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const tomoDatos = (datos) => {

  }
 

  const Component1 = () => <div><><ObtienePromociones/></></div>;
  const Component2 = () => <div><ReempadronaCadete/></div>;
  const Component3 = () => <div><><Listado/></></div>;
  const Component4 = () => <div><><Configuraciones/></></div>;
  const Component5 = () => <div><></></div>;


  const handleMenuClick = (component) => {
    setSelectedComponent(component);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const handleInputChange = (e) => {
    localStorage.setItem("nombreCon", e.target.value);
  };

  return (
    <div className="menu-container">
      <nav className="menu">
        <div className="hamburger" onClick={toggleMenu}>
          ☰
        </div>
        <div className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
        <div className="menu-item" onClick={() => handleMenuClick('Component2')}>Reempadrona Cadete</div>
          <div className="menu-item" onClick={() => handleMenuClick('Component1')}>Reempadronamientos</div>

         <div className="menu-item" onClick={() => handleMenuClick('Component3')}>Asistencias</div>
      {Usuario.destino==7?<><div className="menu-item"  onClick={() => handleMenuClick('Component4')}>Configuraciones</div></>:""}  

            {/* <div className="menu-item" disbled onClick={() => handleMenuClick('Component4')}>Prueba</div>

  */}          <div className="menu-item" disbled onClick={() => navitate('/usuarioLogueado')}>Volver</div></div>
      </nav>

      <div className="component-display">
        {selectedComponent === 'Component1' && <Component1 />}
        {selectedComponent === 'Component5' && <Component5 />}
        {selectedComponent === 'Component2' && <Component2 />}
        {selectedComponent === 'Component3' && <Component3 />}
        {selectedComponent === 'Component4' && <Component4 />}
        {selectedComponent === 'Component5' && (() => navitate('/usuarioLogueado'))}
        
      
        
      
      
      </div>
      <div class="footer"><FooterCorp /></div>  
    </div>
  );
};

export default Menu;


