import { useForm } from 'react-hook-form';
import axios from "axios";
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import { useState } from 'react';
import swal from 'sweetalert'
import Modal from 'react-modal';
import { Spinner } from 'reactstrap';
import CargaMasiva from './CargaMasiva.jsx';
function FormEditaDocente (props) {
const[muestroCargaMasiva,setMuestroCargaMasiva]=useState(false)
const[spinnerCargando,setMuestroSpinnerCargando]=useState(false)

  const cookies=new Cookies();
  const ID=cookies.get('id');

  const customStyles = {
    content: {
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
 
    },
  };

  const salgo=()=>{props.myID("")}

  var cierro=false;
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.net.ar/expedientes/'
  
const parametro=props.datos
const usuario=ObtieneDatosCookies()

  const store=async (data)=>{

data.usuarioID=usuario.id
   const respuesta=await axios.post(URI+"docentes/creaDocente/",data)
   if(respuesta.data.id>0){
    alert(respuesta.data.docenteDni +" Creado Correctamente!!")
   
    salgo();
   }else{
    alert(respuesta.data.message +" DNI existente")
   }
}
const actualizoDocente =async(data)=> {

     
      const respuesta=await axios.put(URI+"docentes/actualizaDocente/"+parametro.docenteDni,data)
     queHago(respuesta.data)
     salgo();
  
  
}
  const queHago=async(respuesta)=>{

    if(respuesta.message==="Datos actualizados correctamente!!"){
      swal("Exito en la actualizacion del estado del DNI :"+respuesta.respuesta)
      cierro=true
    }
    else{
      swal("Algo Fallo!, consulte con el area Tecnica error A003!!!")
     
    
       }}
   
  
  const {register, formState: { errors }, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  



  const onSubmit = (data) => {

    if(parametro.id>0){
actualizoDocente(data)
    }else{
store(data)
    }
  }
const closeModalInformeDocentes=()=>{

}

  return (
    <>
    <div >
      <button onClick={()=>setMuestroCargaMasiva(true)}>Carga Masiva</button>
    
    <Modal
        isOpen={muestroCargaMasiva}
       
        onRequestClose={closeModalInformeDocentes}
        style={customStyles}
        ariaHideApp={false}
      >
        <CargaMasiva myID={(value)=>setMuestroCargaMasiva(false)}/>
        {spinnerCargando?<>Cargando Docentes...<Spinner>

        </Spinner></>:""}
      </Modal>
  
      <form  onSubmit={handleSubmit(onSubmit)}>
       


<div class='input-group-text' >

          <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" placeholder="Apellido" 
         {...register('docenteApellido', {
        required: true,
        minLength: 2,
          })}/>
        <label for="floatingInput">Apellido</label>
    </div>
      <div class="form-floating mb-3" >
      <input type="text" class="form-control" id="floatingNombres" placeholder="Nombres" 
      {...register('docenteNombres', {
        required: true,
        minLength: 3,
      })}/>
      <label for="floatingNombres">Nombres</label>
    </div>
    <div class="form-floating mb-3">
      <input type="number" class="form-control" id="floatingDNI" placeholder="DNI" 
      {...register('docenteDni', {
        required: true,
        minLength: 8
      })}/>
      <label for="floatingDNI">DNI</label>
    </div>
  
</div>

<div class="row">

          <div class='input-group-text' >
          <div class="form-floating mb-3">
      <input type="number" class="form-control" id="floatingCelular" placeholder="Celular" 
      {...register('docenteTelefono', {

      })}/>
      <label for="floatingTelefono">Celular</label>
    </div>
    <div class="form-floating mb-3">
      <input type="text" class="form-control" id="floatingDomicilio" placeholder="Domiclio" 
      {...register('docenteDomicilio', {
       
      })}/>
      <label for="floatingDomicilio">Domicilio</label>
    </div>
    <div class="form-floating mb-3">
       <select class="form-select" id="floatingestadoMateria" aria-label="estadoMateria"
  {...register('docenteEstado', {
    required: true
  })}
  >
   
    
    <option class="mb-3" value="Activo">Activo</option>
    <option class="mb-3" value="Inactivo">Inactivo</option>
    
    
  </select>
  <label for="form-floating mb-3">Estado</label>
</div>
<div class="form-floating mb-3">
       <select class="form-select" id="floatingestadoMateria" aria-label="estadoMateria"
  {...register('docenteTipo', {
    required: true
  })}
  >
   
    
    <option class="mb-3" value="Policia Actividad">Policia Actividad</option>
    <option class="mb-3" value="Policia Retirado">Policia Retirado</option>
    <option class="mb-3" value="Personal Civil">Personal Civil</option>
    
    
  </select>
  <label for="form-floating mb-3">Tipo</label>
</div>

<div>
  
</div>
</div>


<div class='input-group-text' style={{alignContent:"center",alignSelf:"center",gridRowStart:"auto"}}>


{
  parametro.id>0?<input type="submit" value="Actualizar" className="btn btn-success" />:<input type="submit" value="Crear Docente" className="btn btn-primary" />
}
<input type="submit" value="Cancelar" className="btn btn-success" onClick={salgo}/>
</div>

</div>
 </form>

   
        
 </div>       
 
      
 </>)
 
 }

export default FormEditaDocente;
